import "./AdultePage.css";
import { Helmet } from "react-helmet";
import HeroSection from "../adulte-page/adulte-sections/hero-sections/HeroSection";
import ListFormationSection from "./adulte-sections/list-formations-sections/ListFormationsSection";

function AdultePage() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Wyze Academy</title>
        <meta
          name="description"
          content="Formations numériques pour adultes, courtes ou longues, distanciel ou présentiel, éligibles CPF, certifiantes et micro-certifiantes"
        />
        <meta
          name="keywords"
          content="formations courtes, formations longues, adultes, en présentiel, en distanciel"
        />
      </Helmet>
      <div className="adulte-page-wrapper">
        <HeroSection />
        <ListFormationSection />
      </div>
    </>
  );
}

export default AdultePage;
