import React, { useState } from "react";
import "./ListFormationSection.css";
import Accordion from "../../../../components/Accordion/Accordion";
import FormationsCourtesData from "./FormationsCourtesData.json";
import FormationsLonguesData from "./FormationsLonguesData.json";
import AdulteFormationInfo from "../../../../components/AdulteFormationInfo/AdulteFormationInfo";
import Strie from "../../../../components/Strie/Strie";

const FormationList = () => {
  const [formations, setFormations] = useState(FormationsCourtesData);

  const formations_per_column = 7;
  const numColumns = Math.ceil(formations.length / formations_per_column);

  const columns = [];

  for (let i = 0; i < numColumns; i++) {
    const columnFormations = formations.slice(
      i * formations_per_column,
      (i + 1) * formations_per_column
    );

    const column = (
      <div
        className={`formation-column ${i === 0 ? "" : "secondary-column"}`}
        key={i}
      >
        {columnFormations.map((formation, index) => (
          <Accordion
            key={index + formation.formation_title}
            title={formation.formation_title}
            center="center"
            textWrap="nowrap"
            textColor="black"
            border={index === 0 ? "none" : ""}
          >
            <AdulteFormationInfo formation={formation} />
          </Accordion>
        ))}
      </div>
    );
    columns.push(column);
  }

  return (
    <div id="list-formations-section" className="list-formation-wrapper">
      <div className="list-formation-container">
        <div className="list-formation-header">
          <a
            className={`list-formation-button ${
              formations === FormationsCourtesData ? "active" : ""
            }`}
            href="#list-formations-section"
            onClick={() => setFormations(FormationsCourtesData)}
          >
            Formations Courtes
          </a>
          <a
            className={`list-formation-button ${
              formations === FormationsLonguesData ? "active" : ""
            }`}
            href="#list-formations-section"
            onClick={() => setFormations(FormationsLonguesData)}
          >
            Formations Longues
          </a>
        </div>
        <div className="list-formation-body">{columns}</div>
      </div>
      <Strie scale={5} color="red" top="20%" zindex={-1} />
      <Strie scale={2} color="red" left="-2%" top="50%" zindex={-1} />
      <Strie scale={4} color="blue" left="95%" top="60%" zindex={-1} />
      <Strie scale={2} color="blue" left="95%" top="37%" zindex={-1} />
    </div>
  );
};

export default FormationList;
