import { useRef, useEffect, useState } from "react";
import { Parallax, Background } from "react-parallax";
import "./HeroSection.css";
import IconNext from "../../../../media/icons/IconNext.jsx";
import HeroVideo from "../../../../media/video/video_banner.mp4";

function HeroSection() {
  /*animation d'apparition sur intersection*/
  const sectionRef = useRef();
  const [isSectionIntersected, setIsSectionIntersected] = useState();
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setIsSectionIntersected(entry.isIntersecting);
    });
    observer.observe(sectionRef.current);
    return () => {
      observer.disconnect();
    };
  });

  return (
    <>
      <Parallax strength={100}>
        <Background className="custom-bg">
          <video
            className="hero-section-video"
            src={HeroVideo}
            autoPlay
            loop
            muted
            playsInline
          />
        </Background>

        <div className="hero-section-wrapper">
          <div
            ref={sectionRef}
            className={`hero-section-container ${
              isSectionIntersected ? "visible" : ""
            }`}
          >
            <div className="hero-section-body">
              <h1 className="hero-section-title bold">Wyze Academy</h1>
              <h6 className="hero-section-slogan">Explore Create Innovate</h6>
              <a href="#formation-section" className="hero-section-button">
                <span className="hero-section-button-weight">
                  Nos formations
                </span>
                <IconNext rotation="rotate-down" width={22} height={22} />
              </a>
            </div>
          </div>
        </div>
      </Parallax>
    </>
  );
}

export default HeroSection;
