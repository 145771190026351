import "./PromotionBanner.css";

export default function PromotionBanner({
  text = "Promotions en cours",
  textColor = "white",
  fontSize = "2.8rem",
  padding = "1.8rem",
  backgroundColor = "#236aae",
  animationDuration = "20s",
  additionaBannerStyles = {},
}) {
  const bannerContainerStyles = {
    backgroundColor: backgroundColor,
    ...additionaBannerStyles,
  };

  const animationStyles = {
    animationDuration: animationDuration,
  };

  const scrollingTestStyles = {
    fontSize: fontSize,
    color: textColor,
    padding: padding,
  };

  return (
    <>
      <div className="promotion-banner" style={bannerContainerStyles}>
        <div className="promotion-banner-content" style={animationStyles}>
          <span
            className="promotion-banner-scrolling-text"
            style={scrollingTestStyles}
          >
            {text}
          </span>
          <span
            className="promotion-banner-scrolling-text"
            style={{ ...scrollingTestStyles, marginLeft: "100vw" }}
          >
            {text}
          </span>
        </div>
      </div>
    </>
  );
}
