import React from "react";
import "./Contact.css";
import Form from "../Form/Form";

export default function Contact({subject=""}) {
  return (
    <div className="contact-container" id="contactSection">
      <h2>Nous contacter</h2>
      <Form currentSubject={subject} />
    </div>
  );
}
