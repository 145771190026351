import { Routes, Route } from "react-router-dom";
import "./App.css";
import HomePage from "./pages/home-page/HomePage.jsx";
import AdultePage from "./pages/adulte-page/AdultePage.jsx";
import ConstructionProgressPage from "./pages/site-under-construction-page/constructionProgressPage.jsx";
import LegalNotices from "./pages/legal-notices/LegalNotices.jsx";

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/formations" element={<AdultePage />} />
      <Route
        path="/construction-progress"
        element={<ConstructionProgressPage />}
      />
      <Route path="/mentions-legales" element={<LegalNotices />} />
      <Route
        path="/mentions-legales/accessibilite"
        element={<LegalNotices activeTabIndex={3} />}
      />
    </Routes>
  );
}

export default App;
