import "./AvisCard.css";

function AvisCard({ picture, description, name }) {
  return (
    <div className="avis-card-container">
      <img
        className="avis-card-container-picture"
        src={picture}
        alt={picture}
      ></img>
      <p className="avis-card-container-description">{description}</p>
      <hr className="avis-card-separator" />
      <div className="avis-card-details">
        <p className="semi-bold">{name}</p>
      </div>
    </div>
  );
}

export default AvisCard;
