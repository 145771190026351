import "./AvisSection.css";
import AvisCard from "../../../../components/AvisCard/AvisCard";
import Picture1 from "../../../../media/images/woman-picture-1.png";
import Picture2 from "../../../../media/images/man-picture.png";
import Picture3 from "../../../../media/images/woman-picture-2.png";

function AvisSection() {
  const stars = 5;
  const renderStars = (stars) => {
    const starElements = [];
    for (let i = 0; i < 5; i++) {
      if (i + 1 <= stars) {
        starElements.push(
          <span key={i} className="star filled">
            ★
          </span>
        );
      } else {
        starElements.push(
          <span key={i} className="star">
            ☆
          </span>
        );
      }
    }
    return starElements;
  };

  return (
    <div className="avis-section-wrapper">
      <div className="avis-section-container">
        <div className="avis-section-header">
          <h6 className="avis-section-subtitle">Témoignages</h6>
          <h2 className="semi-bold avis-section-title">
            Ce que disent nos apprenants
          </h2>
          <div className="avis-section-stars">{renderStars(stars)}</div>
          <p>
            En 2024,{" "}
            <a
              className="avis-section-link bold"
              href="https://www.google.com/search?sa=X&sca_esv=0119435a3a07c38d&hl=fr-FR&biw=1920&bih=919&tbm=lcl&sxsrf=ADLYWILQwg5tAgTraWM8CIFZiNfovp9B9A:1719240249791&q=Wyze+Academy+Avis&rflfq=1&num=20&stick=H4sIAAAAAAAAAONgkxK2MLGwNLQwMQNiYyMDC3NjS4sNjIyvGAXDK6tSFRyTE1NScysVHMsyixexYooBACDWWW9CAAAA&rldimm=8489184618432087398&ved=2ahUKEwjY2vyCvfSGAxV9dqQEHZsfCioQ9fQKegQIMBAH#lkt=LocalPoiReviews"
              target="_blank"
              rel="noopener noreferrer"
            >
              100%
            </a>{" "}
            de nos stagiaires ont apprécié leur formation chez nous.
          </p>
        </div>
        <div className="avis-section-details">
          <AvisCard
            picture={Picture1}
            description={
              "J'ai réalisé une formation de 3 jours Ecommerce. Très bon climat pour permettre un apprentissage adaptée a tout les niveaux."
            }
            name={"Lea"}
          />
          <AvisCard
            picture={Picture2}
            description={
              "J'ai récemment suivi la formation WordPress de 3 jours, et je recommande vivement."
            }
            name={"Amrou"}
          />
          <AvisCard
            picture={Picture3}
            description={
              "La formation WordPress de 21 heures à Wyze-Academy offre une introduction complète et approfondie à WordPress."
            }
            name={"Hadjer"}
          />
        </div>
        {/* <div className="avis-section-footer">
          <h6>
            En 2024, <span className="bold">100%</span> de nos stagiaires ont
            apprécié leur formation chez nous.
          </h6>
        </div> */}
      </div>
    </div>
  );
}

export default AvisSection;
