import { useRef } from "react";
import "./AdulteFormationInfo.css";
import AdulteFormationInfoDetails from "../AdulteFormationInfoDetails/AdulteFormationInfoDetails";
import Form from "../Form/Form";
import Contact from "../Contact/Contact";

function AdulteFormationInfo({ formation }) {
  const moreDialogRef = useRef();
  const moreScrollableRef = useRef();

  const contactDialogRef = useRef();
  const contactScrollableRef = useRef();

  const openModal = (ref, scrollRef) => {
    ref.current?.showModal();
    document.body.style.overflow = "hidden";
    scrollRef.current.scrollTo(0, 0);
  };

  const closeModal = (ref) => {
    ref.current?.close();
    document.body.style.overflow = "unset";
  };

  return (
    <div className="adulte-formation-container">
      <div className="adulte-formation-body">
        {formation.formation_info && (
          <p className="adulte-formation-info">{formation.formation_info}</p>
        )}
        <p className="adulte-formation-date">
          <span className="bold">Prochaines sessions</span> :<br />
          {formation.formation_date_sessions.map((session, idx) => (
            <span key={idx}>
              {session.date_debut}
              {session.date_fin ? ` au ${session.date_fin}` : ""}{" "}
              {idx < formation.formation_date_sessions.length - 1 && "/ "}
            </span>
          ))}
        </p>
        {formation.formation_avis && (
          <p className="adulte-formation-avis">{formation.formation_avis}</p>
        )}
        <p className="adulte-formation-duree">
          Durée de la formation: {formation.formation_duree}
        </p>

        {formation?.formation_prerequis && (
          <p className="adulte-formation-prerequis">
            Prérequis : {formation.formation_prerequis}
          </p>
        )}
        <p className="adulte-formation-lieu">{formation.formation_lieu}</p>
      </div>
      <div className="formation-footer">
        <button
          className="formation-button-more"
          onClick={() => openModal(moreDialogRef, moreScrollableRef)}
        >
          En savoir +
        </button>
        <button
          className="formation-button-contact"
          onClick={() => openModal(contactDialogRef, contactScrollableRef)}
        >
          Nous contacter
        </button>
      </div>

      <dialog className="formation-adulte-more-modal" ref={moreDialogRef}>
        <div
          ref={moreScrollableRef}
          className="formation-adulte-more-modal-wrapper"
        >
          <AdulteFormationInfoDetails formation={formation} />
        </div>
        <button
          className="adulte-formation-details-close-button"
          onClick={() => closeModal(moreDialogRef)}
        >
          &#x2715;
        </button>
      </dialog>
      <dialog
        id="dialooog"
        className="formation-adulte-contact-modal"
        ref={contactDialogRef}
      >
        <div
          ref={contactScrollableRef}
          className="formation-adulte-contact-modal-wrapper"
        >
          <Contact subject={formation.formation_title} />
        </div>
        <button
          className="adulte-formation-details-close-button"
          onClick={() => closeModal(contactDialogRef)}
        >
          &#x2715;
        </button>
      </dialog>
    </div>
  );
}

export default AdulteFormationInfo;
