import React from "react";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaYoutube,
  FaTiktok,
  FaWhatsapp,
} from "react-icons/fa";
import { BiHandicap } from "react-icons/bi";
import "./FooterV3.css";
import { Link } from "react-router-dom";
import logo from "../../media/images/logo_white_wyze_croped.png";
import Qualiopi from "../Qualiopi/Qualiopi";

const Footer = () => {
  return (
    <div className="footer-wrapper">
      <div className="footer-top1"></div>
      <div className="footer-top2"></div>
      <div className="footer-container">
        <div className="footer-body">
          <div className="footer-qualiopi-container">
            <Qualiopi />
          </div>
          <div className="social-networks-container">
            <h4 className="social-networks-title semi-bold">Suivez-nous</h4>
            <div className="social-networks-icons-container">
              <a
                href="https://www.facebook.com/profile.php?id=61559405770522"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Lien vers notre page Facebook"
              >
                <FaFacebook
                  style={{
                    cursor: "pointer",
                    width: "25px",
                    height: "100%",
                    color: "white",
                  }}
                />
              </a>
              <a
                href="https://www.instagram.com/wyze.academy/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Lien vers notre page Instagram"
              >
                <FaInstagram
                  style={{
                    cursor: "pointer",
                    width: "25px",
                    height: "100%",
                    color: "white",
                  }}
                />
              </a>
              <a
                href="https://www.linkedin.com/company/academy-wyze/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Lien vers notre page Linkedin"
              >
                <FaLinkedin
                  style={{
                    cursor: "pointer",
                    width: "25px",
                    height: "100%",
                    color: "white",
                  }}
                />
              </a>
              <a
                href="https://www.youtube.com/@WyzeAcademy"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Lien vers notre chaine Youtube"
              >
                <FaYoutube
                  style={{
                    cursor: "pointer",
                    width: "25px",
                    height: "100%",
                    color: "white",
                  }}
                />
              </a>
              <a
                href="https://www.tiktok.com/@wyze.academy"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Lien vers notre compte Tiktok"
              >
                <FaTiktok
                  style={{
                    cursor: "pointer",
                    width: "25px",
                    height: "100%",
                    color: "white",
                  }}
                />
              </a>
              <a
                href="https://wa.me/0745628826"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Lien pour nous contacter via Whatsapp"
              >
                <FaWhatsapp
                  style={{
                    cursor: "pointer",
                    width: "25px",
                    height: "100%",
                    color: "white",
                  }}
                />
              </a>
            </div>
          </div>

          <div className="plan-container">
            <h4 className="plan-title semi-bold">Plan du site</h4>
            <div className="plan-body">
              <div className="plan-column">
                <a
                  href="/#"
                  className="plan-link"
                  aria-label="Lien vers la page d'accueil"
                >
                  <p className="plan-text">Accueil</p>
                </a>
                <a
                  href="/formations"
                  className="plan-link"
                  aria-label="Lien vers la page avec les formations pour les adultes"
                >
                  <p className="plan-text">Adultes</p>
                </a>
                <a
                  href="/construction-progress?title=Kids"
                  className="plan-link"
                  aria-label="Lien vers la page avec les formations pour les enfants"
                >
                  <p className="plan-text">Kids</p>
                </a>
              </div>
              <div className="plan-column">
                <a
                  href="/construction-progress?title=Ados"
                  className="plan-link"
                  aria-label="Lien vers la page avec les formations pour les adolescents"
                >
                  <p className="plan-text">Adolescents</p>
                </a>
                <a
                  href="/construction-progress?title=Africa"
                  className="plan-link"
                >
                  <p className="plan-text">Africa</p>
                </a>
              </div>
            </div>
          </div>

          <div className="info-container">
            <h4 className="info-title semi-bold">Informations pratiques</h4>
            <div className="info-body">
              <Link
                to="/mentions-legales"
                className="info-link"
                aria-label="Lien vers la page des mentions légales"
              >
                <p className="info-text">Mentions légales</p>
              </Link>

              <Link
                to="/mentions-legales/accessibilite"
                className="info-link"
                id="footer-accessibility-link"
                aria-label="Lien vers la page d'accessibilité"
              >
                <div className="accessibility-link-container">
                  <BiHandicap
                    style={{
                      cursor: "pointer",
                      width: "25px",
                      height: "100%",
                      color: "white",
                    }}
                  />
                  <p className="info-text">Accessibilité</p>
                </div>
              </Link>
              <a
                href="/#contact-section"
                className="info-link"
                rel="noopener noreferrer"
              >
                <p className="info-text">Nous contacter</p>
              </a>
            </div>
            <p className="footer-text footer-adress-responsive">
              Adresse : 60 Av. du Capitaine Glarner, 93400 Saint-Ouen-sur-Seine
            </p>
          </div>
        </div>
        <div className="footer-footer">
          <div className="footer-footer-logo-container">
            <a href={"/#"} className="footer-logo-link-1">
              <img src={logo} alt="Logo Wyze" className="logo-footer-image" />
            </a>
          </div>

          <p className="footer-text footer-adress">
            Adresse : 60 Av. du Capitaine Glarner, 93400 Saint-Ouen-sur-Seine
          </p>
          <div className="copyright-paragraph-container">
            <p className="footer-text">© 2024 Wyze Academy</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
