import "./FormationSection.css";
import Strie from "../../../../components/Strie/Strie";
import FormationCard from "../../../../components/FormationCard/FormationCard";
import AdulteBackground from "../../../../media/images/adulte-formation-background.jpg";
import AdoBackground from "../../../../media/images/ado-formation-background.jpg";
import KidBackground from "../../../../media/images/kid-formation-background.jpg";
import AfricaBackground from "../../../../media/images/africa-formation-background.jpg";
import KidIcon from "../../../../media/icons/kid-icon.png";
import AdoIcon from "../../../../media/icons/ado-icon.png";
import AdulteIcon from "../../../../media/icons/adulte-icon.png";
import AfricaIcon from "../../../../media/icons/africa-icon.png";

function FormationSection() {
  return (
    <section id="formation-section" className="formation-section-wrapper">
      <div className="formation-section-container">
        <h2 className="semi-bold formation-section-title">Nos formations</h2>
        <div className="formation-section-details">
          <FormationCard
            formation_link="formations"
            background={AdulteBackground}
            icon={AdulteIcon}
            icon_width="40px"
            title="Adultes"
            subtitle="Découvrir nos formations"
          />
          <FormationCard
            formation_link="construction-progress"
            background={AdoBackground}
            icon={AdoIcon}
            icon_width="40px"
            title="Ados"
            subtitle="Découvrir nos stages"
          />
          <FormationCard
            formation_link="construction-progress"
            background={KidBackground}
            background_position="center-right"
            icon={KidIcon}
            icon_width="40px"
            title="Kids"
            subtitle="Découvrir nos stages"
          />
          <FormationCard
            formation_link="construction-progress"
            background={AfricaBackground}
            background_position="center-right"
            icon={AfricaIcon}
            icon_width="40px"
            title="Africa"
            subtitle="Découvrir nos formations"
          />
        </div>
      </div>
      <Strie scale={1.5} color="blue" left="-20%" top="20%" />
      <Strie scale={2} color="blue" left="-20%" top="30%" />
      <Strie scale={3} color="red" left="110%" top="0%" />
    </section>
  );
}

export default FormationSection;
