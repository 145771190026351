import "./FormationCard.css";
import { Link } from "react-router-dom";

function FormationCard({ formation_link = "", background, title, subtitle }) {
  const containerStyle = {
    backgroundImage: `url(${background})`,
  };

  return (
    <a
      href={`/${formation_link}?title=${encodeURIComponent(title)}`}
      className="pricing-card-container"
      style={containerStyle}
    >
      <div className="pricing-card-container-header">
        <h2 className="pricing-card-container-title bold">{title}</h2>
      </div>
      <h6 className="pricing-card-container-description">{subtitle}</h6>
    </a>
  );
}

export default FormationCard;
