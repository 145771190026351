import React, { useEffect, useState } from "react";
import "./constructionProgressPage.css";
const ProgressBar = ({ progress, fillColor, backgroundColor }) => {
  const [width, setWidth] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      if (width < progress) {
        setWidth((prevProgress) => Math.min(prevProgress + 1, progress));
      }
    }, progress || 100);

    // Fonction de nettoyage
    return () => clearInterval(interval);
  }, [progress]);

  let color = "#fcc437";
  switch (fillColor) {
    case "red-kids":
      color = "#eaa9a2";
      break;

    case "red-africa":
      color = "#317121"; // Ajoutez d'autres cas ici si nécessaire
    default:
      break;
  }
  return (
    <>
      <div className={`progress-bar ${backgroundColor}`}>
        <div
          className="progress-fill"
          style={{
            width: `${width}%`,
            transition: `width 100ms ease-in-out`,
            backgroundColor: color,
          }}
        ></div>
      </div>
      <div className="progress-label"> {width}% terminé</div>
    </>
  );
};

export default ProgressBar;
