import { useState } from "react";
import Tabs from "./components/Tabs";
import Tab from "./components/Tab";
import CguSection from "./page-sections/CguSection";
import CgvSection from "./page-sections/CgvSection";
import ConfidentialityNoticeSection from "./page-sections/ConfidentialiyNoticeSection";
import PhsAccessibilitySection from "./page-sections/PhsAccessibilitySection";

export default function LegalNotices({ activeTabIndex = 0 }) {
  const [activeTab, setActiveTab] = useState(activeTabIndex);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  return (
    <>
      <Tabs activeTabIndex={activeTab} setActiveTabIndex={handleTabClick}>
        <Tab title={"CGU"}>
          <CguSection />
        </Tab>
        <Tab title={"CGV"}>
          <CgvSection />
        </Tab>
        <Tab title={"Politique de confidentialité"}>
          <ConfidentialityNoticeSection />
        </Tab>
        <Tab title={"Accessibilité"}>
          <PhsAccessibilitySection />
        </Tab>
      </Tabs>
    </>
  );
}
