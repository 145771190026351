import "./CategorieSection.css";
import Card from "../../../../components/Card/Card";
import MarketingIcon from "../../../../media/icons/marketing-icon.png";
import SeoIcon from "../../../../media/icons/seo-icon.png";
import DevelopmentIcon from "../../../../media/icons/development-icon.png";
import DesignIcon from "../../../../media/icons/design-icon.png";
import IaIcon from "../../../../media/icons/ia-icon.png";
import CyberIcon from "../../../../media/icons/cyber-securite-icon.png";

function CategorieSection() {
  return (
    <div className="categorie-section-wrapper">
      <div className="categorie-section-container">
        <Card
          icon={DevelopmentIcon}
          icon_width="30px"
          title="Développement web"
        >
          Créez des sites et des applications mobiles en utilisant des langages
          de programmation et des bases de données
        </Card>
        <Card icon={MarketingIcon} icon_width="30px" title="Marketing Digital">
          Maitrisez l’ensemble des stratégies de promotion et de vente sur les
          réseaux et sur le web
        </Card>
        <Card icon={DesignIcon} icon_width="30px" title="Design">
          Créez les visuels d’un site web ou d’une application mobile en prenant
          en compte les besoins des utilisateurs
        </Card>
        <Card icon={IaIcon} icon_width="30px" title="Intelligence Artificielle">
          Découvrez les outils d’IA les plus connus et maîtrisez les prompts
          afin d’optimiser vos recherches et vos rendus
        </Card>
        <Card icon={CyberIcon} icon_width="30px" title="Cybersécurité">
          Apprenez les pratiques visant à protéger les systèmes informatiques,
          les données et les utilisateurs contre les attaques numériques
        </Card>
        <Card icon={SeoIcon} icon_width="30px" title="SEO">
          Acquérez les techniques visant à améliorer le positionnement d’un site
          web dans les résultats des moteurs de recherche de Google
        </Card>
      </div>
    </div>
  );
}

export default CategorieSection;
