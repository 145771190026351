import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";

import App from "./App.jsx";
import "./index.css";
// import Footer from "./components/Footer/Footer.jsx";
// import Footer from "./components/Footer/FooterV2.jsx";
import Footer from "./components/Footer/FooterV3.jsx";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop.jsx";
import Cookie from "./components/CookiesConsent/Cookie.jsx";
// import CookieConsent from "./components/CookiesConsent/CookiesConsent.jsx";
import BackToTopButton from "./components/CookiesConsent/BackToTop.jsx";
import Navbar from "./components/Navbar/Navbar.jsx";

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Router>
      <Navbar />
      <ScrollToTop />
      <App />
      <Footer />
      <Cookie />
      <BackToTopButton />
    </Router>
  </React.StrictMode>
);
