import { useRef, useEffect, useState } from "react";
import "./IntroSection.css";
import Card from "../../../../components/Card/Card";
import Strie from "../../../../components/Strie/Strie";
import CertificationIcon from "../../../../media/icons/certification-icon.png";
import FormateurIcon from "../../../../media/icons/formateur-icon.png";
import BuildIcon from "../../../../media/icons/build-icon.png";

function IntroSection() {
  /*animation d'apparition sur intersection*/
  const sectionRef = useRef();
  const [isSectionIntersected, setIsSectionIntersected] = useState();
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setIsSectionIntersected(entry.isIntersecting);
    });
    observer.observe(sectionRef.current);
    return () => {
      observer.disconnect();
    };
  });

  return (
    <div className="home-sections-wrapper">
      <div
        ref={sectionRef}
        className={`intro-section-container transparent-container ${
          isSectionIntersected ? "visible" : ""
        }`}
      >
        <div className="intro-section-header">
          <h6>Wyze Academy</h6>
          <h2 className="semi-bold intro-section-title">L’humain avant tout</h2>
          <p className="intro-section-subtext">
            Tous nos formateurs prennent soin de leurs apprenants : nous vous
            accompagnerons jusqu’à votre réussite.
          </p>
        </div>
        <div className="intro-section-body">
          <Card
            center="center"
            icon={CertificationIcon}
            icon_width="40px"
            title="Certifications"
          >
            Nos formations courtes sont certifiées par la{" "}
            <span className="bold">blockchain</span>.<br />
            {/* Les titres professionnels sont certifiés par{" "}
            <span className="bold">France Compétences</span>. */}
          </Card>
          <hr className="intro-section-body-separator" />
          <Card
            center="center"
            icon={FormateurIcon}
            icon_width="40px"
            title="Formateurs diplômés"
            description="Nos formateurs sont des experts diplômés et passionnés. Leur expérience et leur savoir-faire vous garantissent une formation de qualité, adaptée aux besoins du marché."
          >
            Nos formateurs sont diplômés, fournissent des{" "}
            <span className="bold">supports</span> et dispensent une{" "}
            <span className="bold">pédagogie adaptée</span>.
          </Card>
          <hr className="intro-section-body-separator" />
          <Card
            center="center"
            icon={BuildIcon}
            icon_width="40px"
            title="Accompagnement"
            description="Nous utilisons des outils et technologies de pointe. Nos ressources modernes vous permettent de travailler sur des projets concrets et d'acquérir une expérience pratique précieuse."
          >
            Notre centre de formation à{" "}
            <span className="bold">taille humaine</span> sera constamment à{" "}
            <span className="bold">vos côtés</span> pour vous menez à{" "}
            <span className="bold">l’excellence</span>.
          </Card>
        </div>
      </div>
      <Strie scale={5} color="red" left="-20%" top="20%" zindex={-1} />
      <Strie scale={2} color="red" left="-20%" top="40%" zindex={-1} />
      <Strie scale={6} color="blue" left="110%" top="50%" zindex={-1} />
    </div>
  );
}

export default IntroSection;
