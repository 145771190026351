import React, { useEffect, useState } from "react";
import { FaArrowUp } from "react-icons/fa";
import "./BackToTop.css"; // Import the CSS you just created

const BackToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Function to handle scroll event
    const handleScroll = () => {
      // Show the button when user scrolls down 200px
      if (window.scrollY > 200) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    // Add event listener when component mounts
    window.addEventListener("scroll", handleScroll);

    // Remove event listener when component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div
      className={`btn-up-container ${isVisible ? "visible" : ""}`}
      onClick={scrollToTop}
      role="button"
      tabIndex="0"
      aria-label="Scroll to top"
    >
      <div className="btn-up">
        <FaArrowUp size={24} />
      </div>
    </div>
  );
};

export default BackToTopButton;
