import React, { useEffect, useState } from "react";
import "./AdulteFormationInfoDetails.css";
import WyzeLogo from "../../media/images/logo_black_wyze.png";

function AdulteFormationInfo({ formation }) {
  const [imageHeaderFormation, setImageHeaderFormation] = useState(null);
  const [imageFooterFormation, setImageFooterFormation] = useState(null);

  const getImage = async (formationTitle, type) => {
    const fileName = formationTitle
      .replace(/\s+/g, "_")
      .toLowerCase()
      .replace(/é/g, "e");
    try {
      const imageModule = await import(
        `../../media/images/formation_longue_images/${fileName}_${type}_image.png`
      );
      return imageModule.default;
    } catch (error) {
      console.error("Error loading image:", error);
      return null;
    }
  };

  useEffect(() => {
    const loadImage = async () => {
      if (formation.formation_type === "longue") {
        const src = await getImage(formation.formation_title, "header");
        setImageHeaderFormation(src);
      }
    };

    loadImage();
  });

  useEffect(() => {
    const loadImage = async () => {
      if (formation.formation_type === "longue") {
        const src = await getImage(formation.formation_title, "footer");
        setImageFooterFormation(src);
      }
    };

    loadImage();
  });

  const getPDF = async (formationTitle) => {
    const fileName = formationTitle
      .replace(/\s+/g, "_")
      .toLowerCase()
      .replace(/é/g, "e");
    try {
      const pdfModule = await import(
        `../../media/assets/brochures/brochure_${fileName}.pdf`
      );
      return pdfModule.default;
    } catch (error) {
      console.error("Error loading PDF:", error);
      return null;
    }
  };

  const handleDownload = async (formationTitle) => {
    const fileUrl = await getPDF(formationTitle);

    if (!fileUrl) {
      console.error("File not found");
      return;
    }

    const fileName =
      formationTitle.replace(/\s+/g, "_").toLowerCase().replace(/é/g, "e") +
      ".pdf";
    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      {formation.formation_type === "courte" && (
        <div className="adulte-formation-courte-details-container">
          <div className="adulte-formation-courte-details-header">
            {formation.formation_title && <h1>{formation.formation_title}</h1>}
          </div>
          <div className="adulte-formation-courte-details-body">
            <div className="adulte-formation-courte-details-info">
              {formation.formation_duree && (
                <p>
                  <span className="bold">Durée de la formation :</span>{" "}
                  {formation.formation_duree}
                </p>
              )}
              {formation.formation_prerequis && (
                <p>
                  <span className="bold">Prérequis :</span>{" "}
                  {formation.formation_prerequis}
                </p>
              )}
              {formation.formation_rythme && (
                <p>
                  <span className="bold">Rythme :</span>{" "}
                  {formation.formation_rythme}
                </p>
              )}
              {formation.formation_modalites_evaluations && (
                <p>
                  <span className="bold">Modalités d’évaluations :</span>{" "}
                  {formation.formation_modalites_evaluations.map(
                    (modalite, idx) => (
                      <span key={idx}>
                        {modalite}
                        {idx <
                          formation.formation_modalites_evaluations.length -
                            1 && ", "}
                      </span>
                    )
                  )}
                </p>
              )}
              {formation.formation_nombre_apprenants_presentiel && (
                <p>
                  <span className="bold">
                    Nombre d’apprenants en présentiel :
                  </span>{" "}
                  {formation.formation_nombre_apprenants_presentiel}
                </p>
              )}
              {formation.formation_date_maj && (
                <p>
                  <span className="bold">
                    Date de mise à jour du programme de formation :
                  </span>{" "}
                  {formation.formation_date_maj}
                </p>
              )}
            </div>
            <div className="adulte-formation-courte-details-days">
              {formation.formation_activites_jour_par_jour.map((day, index) => (
                <div
                  key={index}
                  className="adulte-formation-courte-day-container"
                >
                  <div className="adulte-formation-courte-day-header">
                    <h4 className="adulte-formation-courte-day-number">
                      Jour {day.jour}
                    </h4>
                    <h4 className="adulte-formation-courte-day-title">
                      {day.titre}
                    </h4>
                  </div>
                  <div className="adulte-formation-courte-day-body">
                    <ol className="adulte-formation-courte-day-list-actvities">
                      {day.activites.map((activite, idx) => (
                        <li
                          key={idx}
                          className="adulte-formation-courte-day-activite"
                        >
                          <span className="day-activite-number">
                            {idx + 1 < 10 ? "0" + (idx + 1) : idx + 1}
                          </span>
                          <span className="day-activite-name">{activite}</span>
                        </li>
                      ))}
                    </ol>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="adulte-formation-courte-details-footer">
            <a
              href="tel:+33 7 45 62 88 26"
              className="adulte-formation-courte-details-contact-button"
            >
              Contacter un conseiller
            </a>
            <button
              className="adulte-formation-courte-details-download-button"
              onClick={() => handleDownload(formation.formation_title)}
            >
              Télécharger le programme
            </button>
          </div>
        </div>
      )}
      {formation.formation_type === "longue" && (
        <div className="adulte-formation-longue-details-container">
          <div className="adulte-formation-longue-details-header">
            <img
              className="adulte-formation-longue-details-header-image"
              src={imageHeaderFormation}
              alt="header formation"
            />
            <div className="adulte-formation-longue-details-header-left">
              <img src={WyzeLogo} width={"50%"} alt="Wyze logo" />
              <h1 className="adulte-formation-longue-span-formation">
                {"Formation "}
              </h1>
              {formation.formation_title && (
                <h1 className="adulte-formation-longue-header-title">
                  {formation.formation_title}
                </h1>
              )}
            </div>
          </div>
          <div className="adulte-formation-longue-details-body">
            <div className="adulte-formation-longue-details-info">
              <div className="adulte-formation-longue-details-info-text">
                {" "}
                {formation.formation_duree && (
                  <p>
                    <span className="bold">Durée de la formation :</span>{" "}
                    {formation.formation_duree}
                  </p>
                )}
                {formation.formation_prerequis && (
                  <div>
                    {" "}
                    <p className="bold">Prérequis :</p>
                    {formation.formation_prerequis.map((prerequis, idx) => (
                      <p key={idx}>{prerequis}</p>
                    ))}
                  </div>
                )}
                {formation.formation_rythme && (
                  <p>
                    <span className="bold">Rythme :</span>{" "}
                    {formation.formation_rythme}
                  </p>
                )}
                {formation.formation_info && (
                  <p>
                    <span className="bold">Certification :</span>{" "}
                    {formation.formation_info}
                  </p>
                )}
              </div>
            </div>
            <div className="adulte-formation-longue-details-competences">
              <h1 className="adulte-formation-longue-details-competences-title">
                Compétences attestées
              </h1>
              {formation.formation_competences_attestees.map(
                (competences, idx) => (
                  <div
                    key={idx}
                    className="adulte-formation-longue-competence-container"
                  >
                    <h2 className="adulte-formation-longue-competence-title">
                      {competences.titre}
                    </h2>
                    <ul className="adulte-formation-longue-competence-list">
                      {competences.competences.map((competence, idx) => (
                        <li
                          key={idx}
                          className="adulte-formation-longue-competence-list-item"
                        >
                          {competence}
                        </li>
                      ))}
                    </ul>
                  </div>
                )
              )}
            </div>
            <div className="adulte-formation-longue-details-price">
              <div className="adulte-formation-longue-details-price-right">
                <h1>Financement :</h1>
                <h1>
                  {formation.formation_prix.toLocaleString("fr-FR")}
                  {" €"}
                </h1>
                <ul className="adulte-formation-longue-details-price-list">
                  <li>Formation financable sous conditions</li>
                  <li>Paiement jusqu'à 4 fois sans frais </li>
                </ul>
              </div>
              <img
                className="adulte-formation-longue-details-footer-image"
                src={imageFooterFormation}
                alt="footer formation"
              />
              {/* <Strie scale={3} color="red" left="-8%" top="50%" zindex={1} />
              <Strie scale={1.5} color="red" left="40%" top="50%" zindex={1} /> */}
            </div>
            <div className="adulte-formation-longue-details-agency">
              <p className="adulte-formation-longue-details-agency-text">
                Les meilleurs étudiant.e.s seront pris.e.s en stage dans notre
                start-up, la Wyze Agency.
              </p>
            </div>
          </div>
          <div className="adulte-formation-longue-details-footer">
            <a
              href="tel:+33 7 45 62 88 26"
              className="adulte-formation-longue-details-contact-button"
            >
              Contacter un conseiller
            </a>
          </div>
        </div>
      )}{" "}
    </div>
  );
}

export default AdulteFormationInfo;
