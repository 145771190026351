import "./Card.css";

function Card({ icon, title, icon_width, children }) {
  return (
    <div className={`card-container`}>
      <img src={icon} alt={icon} width={icon_width}></img>
      <h6 className="semi-bold card-container-title">{title}</h6>
      <p className="card-container-description">{children}</p>
    </div>
  );
}

export default Card;
