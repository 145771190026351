import { Parallax } from "react-parallax";
import "./BannerSection.css";
import BannerImage from "../../../../media/images/banner-image.jpg";

function BannerSection() {
  return (
    <div className="banner-section-wrapper">
      <Parallax
        blur={0}
        bgImage={BannerImage}
        bgImageAlt="wyze banner"
        strength={100}
      >
        <div className="banner-section-container"></div>
      </Parallax>
    </div>
  );
}

export default BannerSection;
