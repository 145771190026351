import "./HomePage.css";
import { Helmet } from "react-helmet";
import HeroSection from "./home-sections/hero-section/HeroSection";
import IntroSection from "./home-sections/intro-section/IntroSection";
import AdulteSection from "./home-sections/adulte-section/AdulteSection";
import KidsSection from "./home-sections/kids-section/KidsSection";
import AfricaSection from "./home-sections/africa-section/AfricaSection";
import VideoSection from "./home-sections/video-section/VideoSection";
import CategorieSection from "./home-sections/categorie-section/CategorieSection";
import FormationSection from "./home-sections/formation-section/FormationSection";
import AvisSection from "./home-sections/avis-section/AvisSection";
import BannerSection from "./home-sections/banner-section/BannerSection";
import FaqSection from "./home-sections/faq-section/FaqSection";
import ContactSection from "./home-sections/contact-section/Contact";
import PromotionBanner from "../../components/PromotionBanner/PromotionBanner";

function HomePage() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Wyze Academy</title>
        <meta
          name="description"
          content="Centre de formation numérique, pour enfants et adultes. Formations éligibles CPF, certifiantes et micro-certifiantes via la blockchain."
        />
        <meta
          name="keywords"
          content="formations courtes, formations longues, adultes, en présentiel, en distanciel"
        />
      </Helmet>
      <div className="home-page-wrapper">
        <HeroSection />
        <PromotionBanner
          text="Offres de lancement : toutes nos formations de 3 jours sont à 699 Є au lieu de 2300 Є
             et nos formations de 5 jours à 999 Є au lieu de 3300 Є"
          fontSize="2.3rem"
          padding="1.8rem"
          animationDuration="40s"
        />
        <IntroSection />
        <AdulteSection />
        <KidsSection />
        <AfricaSection />
        <VideoSection />
        <CategorieSection />
        <FormationSection />
        <AvisSection />
        <BannerSection />
        <FaqSection />
        <ContactSection />
      </div>
    </>
  );
}

export default HomePage;
