import Accordion from "../../../../components/Accordion/Accordion";
import Strie from "../../../../components/Strie/Strie";
import "./FaqSection.css";

function FaqSection() {
  return (
    <section id="faq-section" className="faq-section-wrapper">
      <div className="faq-section-container">
        <div className="faq-section-header">
          <h6 className="faq-section-subtitle">FAQ</h6>
          <h3 className="semi-bold faq-section-title">
            Questions fréquemment posées
          </h3>
        </div>
        <div className="faq-section-body">
          <Accordion
            title={"Comment trouver la formation répondant à mes attentes ?"}
          >
            <p className="faq-response">
              Nous proposons des formations dans les domaines du Développement
              Web, du Marketing Digital et du Design. <br />
              <br />
              Pour toute question concernant votre orientation, vous pouvez
              contacter notre équipe pédagogique{" "}
              <a className="faq-link" href="tel:0745628826">
                0745628826
              </a>{" "}
              (prix d’un appel local depuis un poste fixe) ou par mail à{" "}
              <a className="faq-link" href="mailto:contact@wyze-academy.com">
                contact@wyze-academy.com
              </a>
              .
            </p>
          </Accordion>
          <Accordion
            title={"Ai-je le niveau requis pour suivre ma formation ?"}
          >
            <p className="faq-response">
              Pour accéder à une formation, le·la stagiaire doit posséder le
              niveau requis indiqué dans la fiche programme. <br />
              <br />
              L’équipe pédagogique se tient également à la disposition des
              stagiaires pour évaluer leur niveau et les orienter dans le choix
              d’une formation.
            </p>
          </Accordion>
          {/* <Accordion title={"Comment financer ma formation ?"}>
            <p className="faq-response">
              Wyze Academy propose un catalogue de formations éligibles au
              financement CPF et l’enrichit au quotidien.
            </p>
          </Accordion> */}
          <Accordion title={"Qui sera mon formateur ?"}>
            <p className="faq-response">
              {" "}
              Tous nos formateurs sont des professionnels et spécialistes dans
              leurs métiers respectifs : Web, Marketing & Design. <br />
              <br />
              Ils allient expertise technique et qualités pédagogiques.{" "}
            </p>
          </Accordion>
          <Accordion title={"Aurai-je besoin de matériel ?"}>
            <p className="faq-response">
              {" "}
              En présentiel, chaque stagiaire (enfant/ado/adulte) dispose d’un
              ordinateur connecté à Internet haut débit.
              <br />
              <br />
              Des matériels additionnels peuvent être mis à disposition des
              stagiaires selon le type de formation.
              <br />
              <br />
              En distanciel, vous aurez besoin d’un PC/MAC et d’une connexion
              Internet.{" "}
            </p>
          </Accordion>
          <Accordion title={"Aurai-je des supports de cours ?"}>
            <p className="faq-response">
              Les supports de cours sont remis au stagiaire au fur-et-à-mesure
              de la formation. Ils sont certifiés par l’équipe pédagogique de
              Wyze Academy.{" "}
            </p>
          </Accordion>
          <Accordion
            title={
              "Quel est le nombre de participants par session de formation ?"
            }
          >
            <p className="faq-response">
              Chaque session (formations en présentiel) comprend 6 à 8 personnes
              maximum. Pour les enfants, nous limitons le groupe à 10 avec deux
              intervenantes.
            </p>
          </Accordion>
          <Accordion title={"Quels sont les horaires de ma formation ?"}>
            <p className="faq-response">
              {" "}
              Les stages débutent à 9h et se terminent à 17h (heure de Paris).
              Les horaires peuvent être modulés en fonction des éventuelles
              contraintes des participants, avec une pause par demi-journée.{" "}
            </p>
          </Accordion>
        </div>
      </div>
      <Strie scale={10} color="red" left="0%" top="50%" zindex={-1} />
      <Strie scale={2} color="red" left="0%" top="85%" zindex={-1} />
      <Strie scale={6} color="blue" left="100%" top="50%" zindex={-1} />
    </section>
  );
}

export default FaqSection;
