import { useRef, useEffect, useState } from "react";
import { Parallax } from "react-parallax";
import "./VideoSection.css";
import Strie from "../../../../components/Strie/Strie";
import PlayIcon from "../../../../media/icons/PlayIcon";
import HaciendaImage from "../../../../media/images/hacienda-image.png";

function VideoSection() {
  const dialogRef = useRef();
  const videoRef = useRef();
  const [player, setPlayer] = useState(null);

  /* animation d'apparition sur intersection */
  const sectionRef = useRef();
  const [isSectionIntersected, setIsSectionIntersected] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setIsSectionIntersected(entry.isIntersecting);
    });
    observer.observe(sectionRef.current);
    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    // Charger l'API JavaScript de YouTube
    const tag = document.createElement("script");
    tag.src = "https://www.youtube.com/iframe_api";
    const firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    // Initialiser le lecteur YouTube une fois l'API chargée
    window.onYouTubeIframeAPIReady = () => {
      const newPlayer = new window.YT.Player(videoRef.current, {
        events: {
          onReady: (event) => {
            setPlayer(event.target);
          },
        },
      });
    };
  }, []);

  const openModal = () => {
    dialogRef.current?.showModal();
    document.body.style.overflow = "hidden";
    if (player) {
      player.playVideo();
    }
  };

  const closeModal = () => {
    dialogRef.current?.close();
    document.body.style.overflow = "unset";
    if (player) {
      player.pauseVideo();
    }
  };

  return (
    <div className="video-section-wrapper">
      <Parallax
        blur={2}
        bgImage={HaciendaImage}
        bgImageAlt="the cat"
        strength={50}
      >
        <div
          ref={sectionRef}
          className={`video-section-container ${
            isSectionIntersected ? "visible" : ""
          }`}
        >
          <button className="video-section-button" onClick={openModal}>
            <PlayIcon width={64} height={64} color="white" />
          </button>
          <h2 className="semi-bold video-section-title">
            Nous vous poussons à l'excellence
          </h2>
          <dialog className="video-section-player" ref={dialogRef}>
            <div className="video-wrapper">
              <iframe
                id="videoPlayer"
                ref={videoRef}
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/JQMRTb_mvDs?enablejsapi=1"
                title="Bienvenue chez Wyze Academy"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </div>
            <button
              className="video-section-player-button"
              onClick={closeModal}
            >
              &#x2715;
            </button>
          </dialog>
        </div>
      </Parallax>
      <Strie scale={4} color="red" zindex={1} top="15%" />
      <Strie scale={1} color="blue" left="96%" top="40%" zindex={1} />
      <Strie scale={4} color="blue" left="100%" top="40%" zindex={1} />
    </div>
  );
}

export default VideoSection;
