import { useRef, useEffect, useState } from "react";
import { Parallax, Background } from "react-parallax";
import "./HeroSection.css";
import IconNext from "../../../../media/icons/IconNext.jsx";
import HeroBackground from "../../../../media/images/formations_adultes_choix2.jpg";

function HeroSection() {
  /*animation d'apparition sur intersection*/
  const sectionRef = useRef();
  const [isSectionIntersected, setIsSectionIntersected] = useState();
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setIsSectionIntersected(entry.isIntersecting);
    });
    observer.observe(sectionRef.current);
    return () => {
      observer.disconnect();
    };
  });

  return (
    <Parallax
      blur={0}
      bgImage={HeroBackground}
      bgImageAlt="adulte section"
      bgImageStyle={{ objectFit: "cover" }}
      strength={400}
    >
      <div className="adulte-hero-section-wrapper">
        <div
          ref={sectionRef}
          className={`adulte-hero-section-container ${
            isSectionIntersected ? "visible" : ""
          }`}
        >
          <div className="adulte-hero-section-body">
            <h1 className="adulte-hero-section-title bold">
              Nos formations adultes
            </h1>
            <a
              href="#list-formations-section"
              className="adulte-hero-section-cta"
            >
              <span>Découvrir</span>
              <IconNext rotation="rotate-down" width={22} height={22} />
            </a>
          </div>
        </div>
      </div>
    </Parallax>
  );
}

export default HeroSection;
