import { useRef, useEffect, useState } from "react";
import "./AdulteSection.css";
import AdulteImage from "../../../../media/images/adulte-image.jpg";
import IconNext from "../../../../media/icons/IconNext";

function AdulteSection() {
  /*animation d'apparition sur intersection*/
  const sectionRef = useRef();
  const [isSectionIntersected, setIsSectionIntersected] = useState();
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setIsSectionIntersected(entry.isIntersecting);
    });
    observer.observe(sectionRef.current);
    return () => {
      observer.disconnect();
    };
  });

  return (
    <div className="home-sections-wrapper">
      <div
        ref={sectionRef}
        className={`adulte-section-container ${
          isSectionIntersected ? "visible" : ""
        }`}
      >
        <div className="adulte-section-description">
          <div className="adulte-section header">
            <h6>Wyze Academy</h6>
            <h2 className="semi-bold">Percer dans le digital</h2>
          </div>
          <p>
            Que vous soyez{" "}
            <span className="bold">
              salarié, entrepreneur, demandeur d’emploi
            </span>{" "}
            ou tout simplement <span className="bold">curieux</span>, vous pourrez développer, améliorer{" "}
            <span className="bold">vos compétences</span> en développement web,
            marketing digital et design. Cela vous permettra de progresser dans
            votre <span className="bold">carrière</span> ou d’en débuter une
            nouvelle.
          </p>
          <a href="./formations" className="adulte-section-button">
            <span>NOS FORMATIONS</span>
            <IconNext rotation="rotate-right" width={12} height={12} />
          </a>
        </div>
        <div className="adulte-section-illustration">
          <img
            className="adulte-section-image"
            src={AdulteImage}
            alt="adulte wyze"
          ></img>
        </div>
      </div>
    </div>
  );
}

export default AdulteSection;
