import React from "react";
import "./ConfidentialityNoticeSection.css";
import Strie from "../../../components/Strie/Strie";

export default function ConfidentialityNoticePage() {
  return (
    <div className="confidentiality-notice-wrapper">
      <div className="confidentiality-notice-container">
        <div className="confidentiality-notice-header">
          <h1>Politique de confidentialité</h1>
        </div>
        <div className="confidentiality-notice-body">
          <div
            className="confidentiality-notice-section"
            id="confidentiality-who-we-are"
          >
            <h2 className="confidentiality-notice-section-title">
              Qui sommes-nous ? :
            </h2>
            <p className="confidentiality-notice-section-text">
              L’adresse de notre site est :{" "}
              <a href="https://www.wyze-academy.com/">
                <span className="bold underline">
                  https://www.wyze-academy.com/
                </span>
              </a>
            </p>
          </div>
          <div
            className="confidentiality-notice-section"
            id="confidentiality-commentaries"
          >
            <h2 className="confidentiality-notice-section-title">
              Commentaires :
            </h2>
            <p className="confidentiality-notice-section-text">
              Quand vous laissez un commentaire sur notre site, les données
              inscrites dans le formulaire de commentaire, ainsi que votre
              adresse IP et l’agent utilisateur de votre navigateur sont
              collectés pour nous aider à la détection des commentaires
              indésirables.
            </p>
            <p className="confidentiality-notice-section-text">
              Une chaîne anonymisée créée à partir de votre adresse e-mail
              (également appelée hash) peut être envoyée au service Gravatar
              pour vérifier si vous utilisez ce dernier.
            </p>
            <p className="confidentiality-notice-section-text">
              Les clauses de confidentialité du service Gravatar sont
              disponibles ici :{" "}
              <a href="https://automattic.com/privacy/">
                <span className="bold underline">
                  https://automattic.com/privacy/
                </span>
              </a>
            </p>
            <p className="confidentiality-notice-section-text">
              Après validation de votre commentaire, votre photo de profil sera
              visible publiquement à coté de votre commentaire.
            </p>

            <Strie scale={5} culor="red" left="-20%" top="20%" zindex={-1} />
            <Strie scale={2} culor="red" left="-19%" top="70%" zindex={-1} />
            <Strie scale={6} culor="blue" left="115%" top="50%" zindex={-1} />
          </div>
          <div
            className="confidentiality-notice-section"
            id="confidentiality-media"
          >
            <h2 className="confidentiality-notice-section-title">Médias :</h2>
            <p className="confidentiality-notice-section-text">
              Si vous téléversez des images sur le site, nous vous conseillons
              d’éviter de téléverser des images contenant des données EXIF de
              coordonnées GPS. Les personnes visitant votre site peuvent
              télécharger et extraire des données de localisation depuis ces
              images.
            </p>
          </div>

          <div
            className="confidentiality-notice-section"
            id="confidentiality-cookies"
          >
            <h2 className="confidentiality-notice-section-title">Cookies :</h2>
            <p className="confidentiality-notice-section-text">
              Si vous déposez un commentaire sur notre site, il vous sera
              proposé d’enregistrer votre nom, adresse e-mail et site dans des
              cookies. C’est uniquement pour votre confort afin de ne pas avoir
              à saisir ces informations si vous déposez un autre commentaire
              plus tard. Ces cookies expirent au bout d’un an.
            </p>
            <br />
            <p className="confidentiality-notice-section-text">
              Si vous vous rendez sur la page de connexion, un cookie temporaire
              sera créé afin de déterminer si votre navigateur accepte les
              cookies. Il ne contient pas de données personnelles et sera
              supprimé automatiquement à la fermeture de votre navigateur.
            </p>
            <br />
            <p className="confidentiality-notice-section-text">
              Lorsque vous vous connecterez, nous mettrons en place un certain
              nombre de cookies pour enregistrer vos informations de connexion
              et vos préférences d’écran. La durée de vie d’un cookie de
              connexion est de deux jours, celle d’un cookie d’option d’écran
              est d’un an. Si vous cochez « Se souvenir de moi », votre cookie
              de connexion sera conservé pendant deux semaines. Si vous vous
              déconnectez de votre compte, le cookie de connexion sera effacé.
            </p>
            <br />
            <p className="confidentiality-notice-section-text">
              En modifiant ou en publiant une publication, un cookie
              supplémentaire sera enregistré dans votre navigateur. Ce cookie ne
              comprend aucune donnée personnelle. Il indique simplement l’ID de
              la publication que vous venez de modifier. Il expire au bout d’un
              jour.
            </p>
          </div>

          <div
            className="confidentiality-notice-section"
            id="confidentiality-third-party-websites-embedded-content"
          >
            <h2 className="confidentiality-notice-section-title">
              Contenu embarqué depuis d’autres sites :{" "}
            </h2>
            <p className="confidentiality-notice-section-text">
              Les articles de ce site peuvent inclure des contenus intégrés (par
              exemple des vidéos, images, articles…). Le contenu intégré depuis
              d’autres sites se comporte de la même manière que si le visiteur
              se rendait sur cet autre site.
            </p>
            <br />
            <p className="confidentiality-notice-section-text">
              Ces sites web pourraient collecter des données sur vous, utiliser
              des cookies, embarquer des outils de suivis tiers, suivre vos
              interactions avec ces contenus embarqués si vous disposez d’un
              compte connecté sur leur site web.
            </p>
          </div>

          <div
            className="confidentiality-notice-section"
            id="confidentiality-usage-and-sharing-of-personal-data"
          >
            <h2 className="confidentiality-notice-section-title">
              Utilisation et transmission de vos données personnelles :
            </h2>
            <p className="confidentiality-notice-section-text">
              Si vous demandez une réinitialisation de votre mot de passe, votre
              adresse IP sera incluse dans l’e-mail de réinitialisation.
            </p>
          </div>

          <div
            className="confidentiality-notice-section"
            id="confidentiality-personal-data-storage-duration"
          >
            <h2 className="confidentiality-notice-section-title">
              Durée de stockage de vos données :
            </h2>
            <p className="confidentiality-notice-section-text">
              Si vous laissez un commentaire, le commentaire et ses métadonnées
              sont conservés indéfiniment. Cela permet de reconnaître et
              approuver automatiquement les commentaires suivants au lieu de les
              laisser dans la file de modération.
            </p>
            <br />
            <p className="confidentiality-notice-section-text">
              Pour les comptes qui s’inscrivent sur notre site (le cas échéant),
              nous stockons également les données personnelles indiquées dans
              leur profil. Tous les comptes peuvent voir, modifier ou supprimer
              leurs informations personnelles à tout moment (à l’exception de
              leur identifiant). Les gestionnaires du site peuvent aussi voir et
              modifier ces informations.
            </p>
          </div>

          <div
            className="confidentiality-notice-section"
            id="confidentiality-personal-data-rights"
          >
            <h2 className="confidentiality-notice-section-title">
              Les droits que vous avez sur vos données :
            </h2>
            <p className="confidentiality-notice-section-text">
              Si vous avez un compte ou si vous avez laissé des commentaires sur
              le site, vous pouvez demander à recevoir un fichier contenant
              toutes les données personnelles que nous possédons à votre sujet,
              incluant celles que vous nous avez fournies. Vous pouvez également
              demander la suppression des données personnelles vous concernant.
              Cela ne prend pas en compte les données stockées à des fins
              administratives, légales ou pour des raisons de sécurité.
            </p>
          </div>

          <div
            className="confidentiality-notice-section"
            id="confidentiality-where-your-data-is-sent"
          >
            <h2 className="confidentiality-notice-section-title">
              Où vos données sont-elles envoyées ?
            </h2>
            <p className="confidentiality-notice-section-text">
              Les commentaires des visiteurs peuvent être vérifiés à l’aide d’un
              service automatisé de détection des commentaires indésirables.
            </p>
          </div>

          <div className="confidentiality-notice-footer">
            <span>
              <p>07 45 62 88 26</p>
              <p>
                <a
                  href="mailto:secretariat@wyze-academy.com"
                  aria-label="Contacter le secretariat via email"
                >
                  <span className="bold unerline">
                    secretariat@wyze-academy.com
                  </span>
                </a>
              </p>
              <p>Siège social : 21 avenue Raspail 93420 VILLEPINTE </p>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
