import logo from "../../media/images/logoQualiopi.webp";
import "./Qualiopi.css";

export default function Qualiopi({ logoWidth = 280, textFontSize = "1rem" }) {
  const logoStyle = { width: logoWidth };
  return (
    <>
      <div className="qualiopi-mention-container">
        <img
          src={logo}
          alt="Logo Qualiopi"
          className="logo-qualiopi-approved"
          style={logoStyle}
        />
        <div className="qualiopi-mention-body" style={logoStyle}>
          <p
            className="qualiopi-mention-text"
            style={{ fontSize: textFontSize }}
          >
            La certification qualité a été délivrée au titre de la catégorie
            d'action suivante :{" "}
            <span className="qualiopi-mention-action">
              <br />
              Action de formation
            </span>
          </p>
        </div>
      </div>
    </>
  );
}
