import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Strie from "../../components/Strie/Strie";
import logoWyzeKids from "../../media/images/logo_white_wyze_kids_croped.png";
import logoWyzeAfrica from "../../media/images/logo_white_wyze_africa_croped.png";
import logoWyze from "../../media/images/logo_black_wyze.png";
import ProgressBar from "./progressBar";
import "./constructionProgressPage.css";

function ConstructionProgressPage() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let title = queryParams.get("title");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  let image = logoWyze;
  let red = "red";
  let blue = "blue";

  switch (title) {
    case "Kids":
      red = "red-kids";
      blue = "blue-kids";
      image = logoWyzeKids;
      break;

    case "Ados":
      red = "red-kids";
      blue = "blue-kids";
      image = logoWyzeKids;
      break;

    case "Africa":
      red = "red-africa";
      blue = "blue-africa";
      image = logoWyzeAfrica;
      break;

    default:
      break;
  }

  return (
    <div className="progress-page-wrapper">
      <div className="progress-container">
        <h1 className={`progress-page-title ${title}`}>
          Wyze {title} en construction
        </h1>
        <h4 className={`progress-page-details ${title}`}>
          Nous travaillons d'arrache-pied pour vous offrir une expérience
          exceptionnelle. Revenez bientôt pour découvrir notre nouvelle
          plateforme!
        </h4>
        <div className="progress-bar-container">
          <ProgressBar progress={75} fillColor={red} backgroundColor={blue} />
        </div>
        <img src={image} alt="Logo Wyze" className="progress-page-logo" />
      </div>
      <Strie scale={3} color={red} left="-2%" top="30%" zindex={-1} />
      <Strie scale={1.2} color={red} left="-2%" top="38%" zindex={-1} />
      <Strie scale={5} color={blue} left="-8%" top="75%" zindex={-1} />
      <Strie scale={2} color={blue} left="-4%" top="80%" zindex={-1} />

      <Strie scale={2} color={blue} left="95%" top="15%" zindex={-1} />
      <Strie scale={4} color={red} left="98%" top="50%" zindex={-1} />
      <Strie scale={6} color={red} left="97%" top="64%" zindex={-1} />
    </div>
  );
}

export default ConstructionProgressPage;
