import { useRef, useEffect, useState } from "react";
import "./AfricaSection.css";
import IconNext from "../../../../media/icons/IconNext";
import AfricaImage from "../../../../media/images/africa-image.jpg";
// import { Link } from "react-router-dom";

function AfricaSection() {
  /*animation d'apparition sur intersection*/
  const sectionRef = useRef();
  const [isSectionIntersected, setIsSectionIntersected] = useState();
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setIsSectionIntersected(entry.isIntersecting);
    });
    observer.observe(sectionRef.current);
    return () => {
      observer.disconnect();
    };
  });

  return (
    <div className="africa-section-wrapper">
      <div
        ref={sectionRef}
        className={`africa-section-container ${
          isSectionIntersected ? "visible" : ""
        }`}
      >
        <div className="africa-section-description">
          <h6>Wyze Africa</h6>
          <h2 className="semi-bold">
            Développez votre business de{" "}
            <span className="semi-bold africa-section-title-span">A à Z</span>
          </h2>
          <p>
            Nos formations <span className="bold">All in One</span> vous
            permettront de <span className="bold">créer votre société</span>, de
            la <span className="bold">promouvoir</span> sur les réseaux et de{" "}
            <span className="bold">créer le site web</span>. Nous vous{" "}
            <span className="bold">accompagnerons</span> de la naissance jusqu’à
            vos premiers gains.{" "}
          </p>
          <a
            href={`/construction-progress?title=Africa`}
            className="africa-section-button"
          >
            <span>NOS FORMATIONS</span>
            <IconNext rotation="rotate-right" width={12} height={12} />
          </a>
        </div>
        <div className="africa-section-illustration">
          <img
            className="africa-section-image"
            src={AfricaImage}
            alt=" africa"
          ></img>
        </div>
      </div>
    </div>
  );
}

export default AfricaSection;
