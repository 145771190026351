import React from "react";
import "./CgvSection.css";
import Strie from "../../../components/Strie/Strie";

const getPdfForm = async (formationTitle) => {
  const fileName = formationTitle
    .toLowerCase()
    .replace(/é/g, "e")
    .replace(/ de /g, "_");

  try {
    const pdfModule = await import(
      `../../../media/assets/forms/${fileName}.pdf`
    );
    return pdfModule.default;
  } catch (error) {
    console.error("Error loading PDF:", error);
    return null;
  }
};

const handleDownload = async (formTitle) => {
  const fileUrl = await getPdfForm(formTitle);

  if (!fileUrl) {
    console.error("File not found");
    return;
  }
  const fileName = formTitle.replace(/_/g, " de ") + ".pdf";

  const link = document.createElement("a");
  link.href = fileUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default function CgvPage() {
  const handleFormRetractionClick = () => {
    handleDownload("Formulaire de rétractation");
  };
  return (
    <div className="cgv-wrapper">
      <div className="cgv-container">
        <div className="cgv-header">
          <h1>Conditions générales de vente</h1>
        </div>
        <div className="cgv-body">
          <div className="cgv-section" id="cgv-definitions">
            <h2 className="cgv-section-title">Définitions</h2>
            <p className="cgv-section-text">
              <span className="bold subtitle-color">
                « Bulletin d'inscription »
              </span>{" "}
              : Désigne le document, constituant une partie du Contrat, rempli
              et signé par l'Apprenant s'inscrivant à titre individuel.
            </p>
            <br />
            <p className="cgv-section-text">
              <span className="bold subtitle-color">
                « Conditions Générales de Vente (CGV) »
              </span>{" "}
              : Désignent le présent document, constituant une partie du
              Contrat.
            </p>
            <br />
            <p className="cgv-section-text">
              <span className="bold subtitle-color">
                « Conseiller en Formation »
              </span>{" "}
              : Désigne la personne représentant l'Ecole, ayant effectivement
              conseillé l'Apprenant pour la conclusion du Contrat.
            </p>
            <br />
            <p className="cgv-section-text">
              <span className="bold subtitle-color">« Contrat » </span>: Désigne
              l'ensemble composé du Bulletin d'inscription, ou du Contrat de
              formation professionnelle, ou de la Convention de formation
              professionnelle et du Programme de formation et les présentes
              Conditions Générales de Ventes (« CGV »).
            </p>
            <br />
            <p className="cgv-section-text">
              <span className="bold subtitle-color">
                « Contrat de formation professionnelle »
              </span>{" "}
              : Désigne l'acte, constituant une partie du Contrat, rempli et
              signé par l'Apprenant qui prend en charge la totalité ou une
              partie du coût du Service.
            </p>
            <br />
            <p className="cgv-section-text">
              <span className="bold subtitle-color">
                « Convention de formation professionnelle »
              </span>{" "}
              : Désigne l'acte, constituant une partie du Contrat, rempli et
              signé par le tiers-financeur concerné en cas de prise en charge.
            </p>
            <br />
            <p className="cgv-section-text">
              <span className="bold subtitle-color">
                « Convention de formation par apprentissage »
              </span>{" "}
              : Désigne l'acte, constituant une partie du Contrat, signé par
              l'Ecole, l'Apprenant et l'Entreprise de l'Apprenant. Cette
              convention définit les conditions générales spécifiques
              applicables aux parties.
            </p>
            <br />
            <p className="cgv-section-text">
              <span className="bold subtitle-color">
                « Entreprise de l'Apprenant »
              </span>{" "}
              : Désigne l'employeur de l'Apprenant, dans le cadre d'une
              Convention de formation par apprentissage et prenant en charge le
              financement du Service.
            </p>
            <br />
            <p className="cgv-section-text">
              <span className="bold subtitle-color">« Nous » </span> /
              <span className="bold subtitle-color"> « Wyze Academy » </span> /
              <span className="bold subtitle-color">« Ecole » </span>: Désignent
              la société par actions simplifiée « Wyze Academy » au capital de 2
              €, immatriculée au TCO de Bobigny (SIRET 98 47 84 64 500017), et dont
              le siège social est situé 21 avenue Raspail–93420 VILLEPINTE. Wyze
              Academy est représentée par M. Bertrand DETRE, son Président
              Directeur Général et M. Demba SOUMARE, son Directeur Général.
            </p>
            <br />
            <p className="cgv-section-text">
              <span className="bold subtitle-color">« Parties » </span> :
              Désignent Vous et Nous pris ensemble.
            </p>
            <br />
            <p className="cgv-section-text">
              <span className="bold subtitle-color">
                « Programme de formation »
              </span>{" "}
              : Désigne le document, constituant une partie du Contrat,
              établissant le programme de la formation de l'Apprenant.
            </p>
            <br />
            <p className="cgv-section-text">
              <span className="bold subtitle-color">« Service(s) » </span> :
              Désigne-le(s) service(s) mis à disposition par Nous, à savoir les
              prestations décrites aux articles 3 à 6, en ce inclus des
              prestations de formation.
            </p>
            <br />
            <p className="cgv-section-text">
              <span className="bold subtitle-color">« Site » </span> : Désigne,
              à la date des présentes, le site dont l'URL est{" "}
              <a
                className="cgv-section-link"
                href="/"
                aria-label="Cliquer ici pour être redirigé vers la page d'accueil"
              >
                www.wyze-academy.com
              </a>
              . Il s'agit du Site hébergeant le Service. Il est entendu que si
              le Service devait être hébergé sur un autre lien URL, il serait
              raisonnable de considérer que le terme « Site » renverrait vers
              cet autre lien URL.
            </p>
            <br />
            <p className="cgv-section-text">
              <span className="bold subtitle-color">« Tiers financeur » </span>:
              Désigne un organisme public, comprenant notamment les opérateurs
              de compétences ou tout autre organisme tiers public, qui prend en
              charge partiellement ou totalement le financement du Service, en
              ce compris la formation.
            </p>
            <br />
            <p className="cgv-section-text">
              <span className="bold subtitle-color">« Vous »</span> /
              <span className="bold subtitle-color"> « Apprenants »</span> :
              Désigne toute personne physique bénéficiant du Service.
            </p>
            {/* <br /> */}
            <Strie scale={5} culor="red" left="-20%" top="20%" zindex={-1} />
            <Strie scale={2} culor="red" left="-19%" top="140%" zindex={-1} />
            <Strie scale={6} culor="blue" left="115%" top="40%" zindex={-1} />
          </div>
          <p className="cgv-section-text">
            Les présentes CGV s'appliquent aux Services achetés par l'Apprenant
            (par lui-même, ou financé par un Tiers financeur ou un Organisme de
            crédit) ou l'Entreprise de l'Apprenant, à l’exception du cas des
            inscriptions réalisées via la plateforme ou l’application «
            moncompteformation ». Dans ce cas, ce sont les Conditions Générales
            d’Utilisation de la plateforme « moncompteformation » qui
            s’appliquent.
          </p>
          <br />
          <p className="cgv-section-text">
            Wyze Academy se réserve la possibilité de modifier ou mettre à jour
            ses CGV à tout moment, après en avoir préalablement informé
            l'Apprenant. Les CGV applicables sont celles disponibles et
            consultables sur le Site au jour de l'inscription à l'exclusion de
            toutes autres.
          </p>
          <div className="cgv-section" id="cgv-presentation">
            <h2 className="cgv-section-title">1. PR&Eacute;SENTATION</h2>
            <p className="cgv-section-text">
              Wyze Academy est un organisme de formation enregistré sous le
              numéro de déclaration d’activité 11 93 10759 93 auprès du préfet
              de la région d’Île de France, dont les services sont consultables
              sur le Site{" "}
              <a
                className="cgv-section-link"
                href="/"
                aria-label="Cliquer ici pour être redirigé vers la page d'accueil"
              >
                www.wyze-academy.com
              </a>{" "}
              et fournis via une plateforme d’apprentissage à distance.
              <br />
              Les Directeurs de la publication du site, de la plateforme et de
              l’organisme de formation sont{" "}
              <span className="bold">M. Bertrand DETRE</span> et{" "}
              <span className="bold">M. Demba SOUMARE</span>.
            </p>
            <p className="cgv-section-text">
              Le Site est hébergé sur www.o2switch.fr, graphiquement localisée à
              Clermont-Ferrand (France). Nous contacter :
            </p>
            <ul className="cgv-section-list">
              <li className="cgv-section-list-item">
                {/* <span>Par téléphone</span> : 06 21 35 38 69 */}
                <span>Par téléphone</span> : 07 45 65 88 26
              </li>
              <li className="cgv-section-list-item">
                <span>Par email</span> :{" "}
                <a
                  href="mailto:contact@wyze-academy.com"
                  aria-label="Cliquer ici pour contacter le secretariat par email"
                >
                  contact@wyze-academy.com
                </a>
              </li>
              <li className="cgv-section-list-item">
                <span>Par courrier</span> : Wyze Academy, 21 avenue Raspail –
                93420 VILLEPINTE, France
              </li>
            </ul>
            {/* <Strie scale={5} culor="red" left="-20%" top="20%" zindex={-1} />
            <Strie scale={2} culor="red" left="-19%" top="70%" zindex={-1} />
            <Strie scale={6} culor="blue" left="115%" top="50%" zindex={-1} /> */}
          </div>
          <div className="cgv-section" id="cgv-objet">
            <h2 className="cgv-section-title">2- OBJET</h2>
            <p className="cgv-section-text">
              Les présentes CGV s’appliquent aux Services proposés par Wyze
              Academy dans le cadre d’une contractualisation formalisant l’achat
              de formation (Contrat ou Convention) conclu avec{" "}
              <span className="bold"> tout Apprenant </span>
              personne physique et/ou{" "}
              <span className="bold">
                tout Tiers Financeur et/ou l’Entreprise de l’Apprenant,{" "}
              </span>{" "}
              peu importe les modalités de son inscription qu’il soit en
              autofinancement et/ou qu’il soit en prise en charge partielle ou
              totale de son projet de formation.
              <br />
              Par ailleurs, les obligations de l'École et de l'Entreprise de
              l’Apprenant sont complétées par des conditions spécifiques
              figurant dans la Convention de formation par apprentissage.
              <br />
              Les CGV sont opposables à l’Apprenant qui reconnaît en avoir eu
              connaissance avant de valider son inscription et les accepter sans
              réserve.
            </p>
          </div>
          <div className="cgv-section" id="cgv-our-services">
            <h2 className="cgv-section-title">3- NOS SERVICES</h2>
            <p className="cgv-section-text">
              Wyze Academy propose notamment des Services de formation à
              distance et en présentiel dans des locaux situés en région
              parisienne, ainsi que des prestations accessoires dont Vous
              bénéficierez et qui forment un tout indivisible. Tous les Services
              proposés par Wyze Academy sont décrits et présentés avec la plus
              grande exactitude possible sur le Site.
            </p>
            <br />
            <p className="cgv-section-text">
              Les Services sont tous disponibles en formation individuelle.
            </p>
            <br />
            <p className="cgv-section-text">
              Pour toute question relative à la pédagogie des programmes de
              formation à distance proposés par Wyze Academy, l’Apprenant peut
              appeler le{" "}
              <span className="bold subtitle-color">07 45 65 88 26</span>, du{" "}
              <span className="bold subtitle-color">
                lundi au samedi de 9h00 à 19h.
              </span>
            </p>
            <br />
            <p className="cgv-section-text">
              Les Services comprennent notamment :
            </p>
            <br />
            <p className="cgv-section-text">
              <span className="bold underline subtitle-color">
                3.1. Des cours{" "}
              </span>{" "}
              <span className="bold">:</span> contenus pédagogiques numériques,
              bibliothèque de cours-vidéos enregistrés ou cours en direct (de
              synthèse, d’approfondissement, de révision).
            </p>
            <br />
            <p className="cgv-section-text">
              <span className="bold underline subtitle-color">
                3.2. Des auto-évaluations
              </span>{" "}
              <span className="bold">:</span> évaluations et entraînements sous
              forme de quiz et cas pratiques.
            </p>
            <br />
            <p className="cgv-section-text">
              <span className="bold underline subtitle-color">
                3.3. Un accompagnement et un encadrement
              </span>{" "}
              <span className="bold">:</span> assistance assurée par les
              formateurs via le forum ou en live, support par des chargés de
              relation apprenant et des techniciens informatiques, planning
              personnalisé, tableau de bord.
            </p>
            <br />
            <p className="cgv-section-text">
              <span className="bold underline subtitle-color">
                3.4. De l’interaction entre apprenants
              </span>{" "}
              <span className="bold">:</span> via des outils de communication et
              des espaces collaboratifs (en ligne et en présentiel facultatif
              selon les formations).
            </p>
            <br />
            <p className="cgv-section-text">
              <span className="bold underline subtitle-color">
                3.5. Des mises en pratique
              </span>{" "}
              <span className="bold">:</span> selon les formations, l'Apprenant
              peut bénéficier sur simple demande d’une convention de stage
              (stage obligatoire ou conseillé).
            </p>
            <br />
            <p className="cgv-section-text">
              <span className="bold underline subtitle-color">
                3.6. Un ou plusieurs logiciel(s) et/ou service(s) tiers en ligne
                et la fourniture de supports écrits, selon les formations.
              </span>
            </p>
          </div>

          <div className="cgv-section" id="cgv-inscription-acces-services">
            <h2 className="cgv-section-title">
              4. INSCRIPTIONS ET ACCÈS AUX SERVICES
            </h2>
            <p className="cgv-section-text">
              Les inscriptions à l’École sont ouvertes toute l’année.
              Conformément à l’article L.444-8 du Code de l'Education reproduit
              en annexe des CGV, le Contrat ne peut être signé, à peine de
              nullité, qu’au terme d’un délai de sept jours francs après sa
              réception.
            </p>
            <br />
            <p className="cgv-section-text">
              <span className="bold underline subtitle-color">
                4.1. La demande d’inscription de l’Apprenant
              </span>{" "}
              est prise en compte à la date fixée sur le Contrat, sous réserve :
              <br />
              &nbsp;&nbsp;&nbsp;
              <span className="bold subtitle-color">4.1.1</span> De réception de
              toutes les pièces constitutives du dossier dûment complétées et
              signées, et
              <br />
              &nbsp;&nbsp;&nbsp;
              <span className="bold subtitle-color">4.1.2</span> De l’accord de
              prise en charge de la formation (si prise en charge de la
              formation par un Tiers Financeur) ou du premier règlement (si
              prise en charge de la formation par l'Entreprise de l'Apprenant ou
              par l'Apprenant directement, avec ou non l'intervention de
              l'Organisme de crédit). La formation commence à compter de la
              délivrance des accès à la plateforme et aux applications mobiles
              de formation à distance.
            </p>
            <br />
            <p className="cgv-section-text">
              <span className="bold underline subtitle-color">
                4.2. L’accès aux Services par l’Apprenant
              </span>{" "}
              se fait sur la plateforme de formation à distance, accessible par
              l’adresse Internet fournie dans l’email envoyé par l’École à la
              confirmation d’inscription. L’Apprenant peut y avoir accès à
              partir d’un ordinateur connecté à Internet, mais également sur
              tablette et smartphone. Dans tous les cas, le coût de la connexion
              à Internet est à la charge de l’Apprenant.
              <br />
            </p>
            <br />
            <p className="cgv-section-text">
              <span className="bold underline subtitle-color">
                4.3. Le transfert de l’Apprenant vers une autre formation
              </span>{" "}
              reste possible dès lors que l'Apprenant en fait la demande dans
              les 3 premiers mois de l'inscription, à l’exception des formations
              prises en charge par des Tiers Financeurs ou via le compte
              personnel de formation (« CPF ») de l'Apprenant. Si le montant de
              la formation initiale est supérieur au transfert, aucun
              remboursement n’est possible. Dans le cas contraire, une
              facturation des frais de formation supplémentaires est établie
              sans possibilité d’échéancier.
              <br />
            </p>
          </div>

          <div className="cgv-section" id="cgv-program-duration">
            <h2 className="cgv-section-title">5. DURÉE DE LA FORMATION</h2>
            <p className="cgv-section-text">
              L’inscription de l’Apprenant et le démarrage de la formation sont
              effectifs conformément aux conditions mentionnées au point 4.1.
              des CGV.
            </p>
            <br />
            <p className="cgv-section-text">
              <span className="bold underline subtitle-color">
                5.1. La période initiale
              </span>{" "}
              se termine à la date choisie au démarrage de la formation, en
              concertation, le cas échéant, avec le Conseiller en Formation, en
              prenant en compte le volume horaire de formation prévu et
              l'intensité hebdomadaire à réaliser par l’Apprenant. Cette date
              figure au Contrat.
            </p>
            <br />
            <p className="cgv-section-text">
              <span className="bold underline subtitle-color">
                5.2. L’échéance de la formation
              </span>{" "}
              est définie par le type de formation suivie et selon les
              conditions figurant dans le Contrat.
            </p>
            <br />
            <p className="cgv-section-text">
              <span className="bold underline subtitle-color">
                5.3. La fin du Contrat
              </span>{" "}
              coïncide avec la date de fin de formation choisie par l’Apprenant
              au moment de son inscription.
            </p>
          </div>

          <div className="cgv-section" id="cgv-success-guaranty">
            <h2 className="cgv-section-title">6. LA « GARANTIE RÉUSSITE »</h2>
            <span className="bold underline subtitle-color">
              6.1. Principe et conditions
            </span>{" "}
            <p className="cgv-section-text">
              La Garantie Réussite est incluse dans le Service et accessible à
              tous les Apprenants. Elle consiste en un maintien du Service au
              bénéfice de l’Apprenant, à l'exclusion des prestations mentionnées
              du point 3.6 des CGV, durant 12 mois et sans surcoût, sous réserve
              du respect des conditions ci-après.
            </p>
            <p className="cgv-section-text">
              <span className="bold font-small subtitle-color">
                &nbsp;&nbsp;&nbsp;6.1.1 &nbsp; En cas d’autofinancement par
                l’Apprenant, de financement via l'Entreprise de l'apprenant
              </span>
              , l’Apprenant et/ou plus généralement, le payeur, devront (i) être
              à jour des règlements pour bénéficier de la Garantie Réussite, et
              (ii) recevoir l’accord de la commission pédagogique de l'École,
              qui appréciera les éléments d'avancée pédagogique attendue
              (travaux obligatoires, résultats obtenus aux évaluations
              intermédiaires et toute autre information portée à sa
              connaissance).
            </p>
            <p className="cgv-section-text">
              <span className="bold font-small subtitle-color">
                &nbsp;&nbsp;&nbsp; 6.1.2 &nbsp; En cas de financement par un
                Tiers Financeur
              </span>
              , l’Apprenant devra justifier d’une assiduité à 100% pour que la
              Garantie Réussite lui soit ouverte. Si l’Apprenant se situe entre
              50% et 100% d’assiduité, la commission pédagogique de l'École
              statuera sur l’attribution de cette garantie. Si le taux
              d’assiduité de l’Apprenant est inférieur à 50%, il ne pourra pas -
              sauf décision exceptionnelle de la commission pédagogique de
              l’Ecole - bénéficier de la Garantie Réussite. La commission
              pédagogique évaluera de manière objective l’implication de
              l’Apprenant à partir des rendus de travaux obligatoires, des
              résultats obtenus aux évaluations intermédiaires et de toute autre
              information qui pourrait être portée à sa connaissance.
            </p>
            <br />
            <span className="bold underline subtitle-color">
              6.2. Modalités de déploiement
            </span>{" "}
            <p className="cgv-section-text">
              Lors de la conclusion du Contrat, l’Apprenant décide, en lien avec
              le Conseiller en Formation, de la date de fin de sa formation.
              Sauf avis contraire de sa part, l’Apprenant bénéficie
              automatiquement de la Garantie Réussite. S’il en remplit les
              conditions d’accès à la date de fin de formation prévue au
              Contrat, et dans les 2 mois qui suivent celle-ci, l’Apprenant doit
              faire part de son souhait de bénéficier de la Garantie Réussite
              auprès de l'École.
            </p>
          </div>

          <div className="cgv-section" id="cgv-fees-and-payment-conditions">
            <h2 className="cgv-section-title">
              7. FRAIS DE SERVICES ET CONDITIONS DE RÈGLEMENT
            </h2>
            <span className="bold underline subtitle-color">
              7.1. Frais de Services
            </span>{" "}
            <p className="cgv-section-text">
              Les prix des Services sont indiqués en euros et toutes taxes
              comprises.
              <br />
              <br />
              Les frais bancaires occasionnés par le mode de paiement choisi par
              l’Apprenant seront à sa charge ou pris en charge par son financeur
              le cas échéant.
            </p>
            <br />
            <span className="bold underline subtitle-color">
              7.2. Conditions de règlement
            </span>{" "}
            <br />
            <p className="cgv-section-text">
              &nbsp;&nbsp;&nbsp;
              <span className="bold font-small subtitle-color">
                7.2.1 &nbsp; En cas d’une formation qu'il finance lui-même
              </span>
              , l'Apprenant est responsable du règlement de l’intégralité des
              frais de Services, au bénéfice de l’École conformément aux
              informations financières figurant dans le Contrat. Dans ce cas et
              conformément à l’
              <span className="italic">
                article L.444-8 du Code de l'éducation
              </span>
              , l’Apprenant devra s’acquitter d’un acompte obligatoire d'un
              montant minimum de 70€ et dont le montant maximum ne saurait
              excéder 30 % des frais de formation. Le solde étant à acquitter en
              respect de l'échéancier validé avec le Conseiller en Formation.
            </p>
            <p className="cgv-section-text">
              <span className="bold font-small subtitle-color">
                &nbsp;&nbsp;&nbsp; 7.2.2 &nbsp; En cas d’une prise en charge par
                un Tiers Financeur
              </span>
              , il appartient à l’Apprenant de formuler lui-même la demande de
              prise en charge du Service auprès du Tiers Financeur et de
              s’assurer de l’acceptation de sa demande. L’Apprenant doit joindre
              à l’École une copie de l’accord de prise en charge par le Tiers
              Financeur.
            </p>
            <p className="cgv-section-text">
              &nbsp;&nbsp;&nbsp;
              <span className="bold font-small subtitle-color">
                7.2.3 &nbsp; En cas d’une prise en charge par la Caisse des
                dépôts (hypothèse particulière du paiement via CPF)
              </span>
              , en tout ou partie, via « moncompteformation », les conditions de
              prise en charge de la formation sont définies dans le Bulletin
              d’inscription.
            </p>
            <br />
            <span className="bold underline subtitle-color">
              7.3. Règlement des heures de présences{" "}
            </span>{" "}
            <br />
            <p className="cgv-section-text">
              En cas de subrogation, les heures effectivement réalisées par
              l’Apprenant font l’objet d’une facturation au Tiers Financeur ou à
              l'Entreprise de l'Apprenant.
            </p>
            <br />
            <span className="bold underline subtitle-color">
              7.4. En cas de règlement échelonné{" "}
            </span>{" "}
            <br />
            <p className="cgv-section-text">
              En cas de paiement échelonné, les sommes dues sont prélevées les
              5, 10 ou 20 du mois suivant l’inscription à un intervalle de 30
              jours calendaires, selon ce qui figure dans le Contrat, ceci étant
              décidé en lien avec le Conseiller en Formation. Si l’échéancier de
              paiement se termine au-delà de la date de fin de formation fixée
              avec le Conseiller en Formation, alors les accès au service sont
              garantis jusqu’à la fin de l’échéancier de paiement.
              <br />
              Les Parties reconnaissent que le service administratif de l’École
              est souverain dans la décision d’accepter ou de refuser le mode de
              paiement, et de réclamer un autre mode de règlement.
            </p>
            <br />
            <span className="bold underline subtitle-color">
              7.5. En cas d’impayés ou incidents de paiement{" "}
            </span>{" "}
            <br />
            <p className="cgv-section-text">
              À la suite d’un incident de paiement, l’Apprenant doit
              impérativement contacter Wyze Academy par téléphone (07 45 65 88
              26) ou par email (
              <a
                href="mailto:contact@wyze-academy.com"
                aria-label="Cliquer ici pour nous contacter par email"
              >
                <span className="bold">contact@wyze-academy.com</span>
              </a>
              ) le service administratif de l’École. Il doit obligatoirement
              adresser, dans un délai de 10 jours calendaires maximum, un
              règlement d’un montant égal au versement non perçu.
              <br />
              <br />
              En outre, à la constatation d’un incident de paiement non
              régularisé, l’École est en droit :
              <br />
              <br />
              &nbsp; - &nbsp; de réclamer le règlement intégral des mensualités
              restantes
              <br />
              &nbsp; - &nbsp; de suspendre les accès à la plateforme de
              formation
              <br />
              &nbsp; - &nbsp; de suspendre le stage éventuellement en cours en
              ayant préalablement prévenu l’Entreprise de l’Apprenant.
            </p>
          </div>

          <div className="cgv-section" id="cgv-retraction-rights">
            <h2 className="cgv-section-title">8. DROIT DE RÉTRACTATION</h2>
            <p className="cgv-section-text">
              Dans le cadre d’un contrat conclu à distance, l’Apprenant dispose
              d’un délai de 14 jours francs, après le jour de la conclusion du
              Contrat, pour exercer son droit de rétractation sans avoir à
              justifier de motifs ni à payer de pénalités, à l’exception, le cas
              échéant, des frais de retour. Si le délai de quatorze jours vient
              à expirer un samedi, un dimanche ou un jour férié ou chômé, il est
              prolongé jusqu’au premier jour ouvrable suivant.
            </p>
            <br />
            <p className="cgv-section-text">
              La demande de rétractation peut se faire par courrier en lettre
              simple auprès de Wyze Academy – 21 avenue Raspail – 93420
              VILLEPINTE ou par email{" "}
              <span className="bold">
                <a
                  href="mailto:resiliation@wyze-academy.com"
                  aria-label="Cliquer ici pour nous contacter par email"
                >
                  <span className="bold">resiliation@wyze-academy.com</span>
                </a>
              </span>{" "}
              sur papier libre ou via le{" "}
              <span
                className="bold link-style"
                id="retraction-form"
                onClick={handleFormRetractionClick}
              >
                formulaire de rétractation
              </span>{" "}
              disponible sur le site internet de l’École et en annexe aux CGV.
              La date de réception de la notification détermine la date de
              rétractation de la formation.
            </p>
            <p className="cgv-section-text">
              En cas d’exercice du droit de rétractation, l’École rembourse
              l’Apprenant de la totalité des sommes qu’il a déjà versées, dans
              les meilleurs délais et au plus tard dans les quatorze jours
              suivant la date à laquelle ce droit a été exercé.
            </p>
          </div>

          <div className="cgv-section" id="cgv-cancellation">
            <h2 className="cgv-section-title">9. RÉSILIATION</h2>
            <span className="bold underline subtitle-color">
              9.1. Résiliation pour cas fortuit ou force majeure (article L.
              444-8 du Code de l’éducation)
            </span>{" "}
            <p className="cgv-section-text">
              Conformément à l’article L.444-8 du Code de l’Éducation reproduit
              en annexe des CGV, le Contrat peut être résilié par l’Apprenant ou
              son représentant légal, si, par suite d’un cas fortuit ou d’une
              force majeure avéré et justifié, il est empêché de suivre la
              formation correspondante. Dans ce cas, la résiliation ne donne
              lieu à aucune indemnité.
            </p>
            <br />
            <span className="bold underline subtitle-color">
              9.2. Résiliation unilatérale dans un délai de 3 mois (article L.
              444-8 du Code de l’éducation)
            </span>{" "}
            <p className="cgv-section-text">
              Jusqu’à l’expiration d’un délai de 3 mois à compter de la date
              d’entrée en vigueur du Contrat, l’Apprenant peut résilier
              unilatéralement le Contrat sans motif.
            </p>
            <p className="cgv-section-text">
              Toute résiliation émanant de l’Apprenant dans ce délai entraînera
              l’application des frais de résiliation suivants :
              <br />
              &nbsp; - &nbsp; Frais de formation : Les sommes déjà versées ne
              seront pas remboursées
            </p>
            <br />
            <span className="bold underline subtitle-color">
              9.3. Modalités de résiliation
            </span>{" "}
            <p className="cgv-section-text">
              Dans les hypothèses de résiliation prévues à l’article 9.1 et 9.2
              des CGV, la demande unilatérale de résiliation doit être notifiée
              à l’École par lettre recommandée avec accusé de réception, en
              précisant le motif s’agissant d’une résiliation dans le cadre de
              l’article 9.1 des CGV. La date de réception de la notification
              détermine la date de résiliation du Service. L’École adressera
              sous 15 jours le décompte des frais de résiliation applicables le
              cas échéant et restitue, s’il y a lieu, les sommes versées par
              l’Apprenant qui ne constituent pas la contrepartie des Services
              effectivement rendus à la date de résiliation.
            </p>
            <br />
            <span className="bold underline subtitle-color">
              9.4. Absence
            </span>{" "}
            <p className="cgv-section-text">
              Toute demande de résiliation ne relevant pas de l’article 9.1 ou
              9.2 des CGV ne sera pas acceptée par l'École, les frais de
              Services seront maintenus conformément au Contrat.
            </p>
            <br />
            <span className="bold underline subtitle-color">
              9.5. Cas particuliers
            </span>{" "}
            <p className="cgv-section-text">
              Pour les formations sous prise en charge par un Tiers Financeur,
              les conditions spécifiques de résiliation autorisées par les Tiers
              Financeurs s’appliquent sachant que pour toute résiliation dans
              les 3 mois, les heures effectuées seront facturées au Tiers
              Financeur et un éventuel complément sera demandé à l’Entreprise de
              l’Apprenant qui intervient dans le cofinancement de la formation
              dans la limite de 30% des frais de Services.
            </p>
            <p className="cgv-section-text">
              Au-delà de 3 mois suivant l’entrée en vigueur du Contrat, les
              conditions spécifiques de résiliation autorisées par les Tiers
              Financeurs s’appliquent sachant que l’intégralité des frais de
              Services restant à la charge de l’Entreprise de l’Apprenant qui
              intervient dans le cofinancement de la formation serait alors
              immédiatement exigible.
            </p>
            <p className="cgv-section-text">
              Dans le cadre d'une Convention de formation par apprentissage, les
              modalités de résiliation sont soumises aux conditions spécifiques
              figurant dans la convention de formation par apprentissage.
            </p>
            <Strie scale={1.5} culor="blue" left="-20%" top="0" zindex={-1} />
            <Strie scale={3} culor="blue" left="-15%" top="50%" zindex={-1} />
            <Strie scale={5} culor="red" left="100%" top="0%" zindex={-1} />
            <Strie scale={3} culor="red" left="110%" top="10%" zindex={-1} />
          </div>

          <div className="cgv-section" id="cgv-force-majeure">
            <h2 className="cgv-section-title">10. FORCE MAJEURE </h2>
            <p className="cgv-section-text">
              Aucune des deux Parties ne sera tenue pour responsable vis-à-vis
              de l'autre de la non-exécution ou des retards dans l'exécution
              d'une obligation du Contrat qui seraient dus au fait de l'autre
              partie consécutivement à la survenance d'un cas de force majeure
              tel que défini par l’article 1218 du Code Civil.
            </p>
            <br />
            <p className="cgv-section-text">
              Le cas de force majeure suspend les obligations nées du Contrat
              pendant toute la durée de son existence.
            </p>
            <br />
            <p className="cgv-section-text">
              En cas de survenance d’un cas de force majeure, l’Apprenant ou
              l’École pourront notifier à l’autre Partie la résiliation du
              Contrat par lettre recommandée avec avis de réception en précisant
              les motifs de cette résiliation et apportant la preuve de la cause
              de force majeure. A défaut de réponse, la résiliation prendra
              effet dans un délai de huit jours après réception de la lettre.
            </p>
            <br />
            <p className="cgv-section-text">
              Si la résiliation du Contrat intervient dans le cadre de la force
              majeure, les règles d’indemnisation de l’article 9.1 des CGV
              trouveront également à s’appliquer.
            </p>
          </div>

          <div className="cgv-section" id="cgv-parties-obligations">
            <h2 className="cgv-section-title">11. OBLIGATIONS DES PARTIES </h2>
            <span className="bold underline subtitle-color">
              11.1. Respect du Règlement intérieur
            </span>{" "}
            <p className="cgv-section-text">
              Lors de son inscription à l’École, l’Apprenant s’engage à
              respecter les engagements visés au Règlement intérieur dont il
              aura accepté les termes lors de sa première connexion à la
              plateforme d’apprentissage à distance. Tout manquement avéré et
              dûment constaté aux règles du Règlement intérieur de l’École peut
              conduire à des sanctions jusqu’à l’exclusion de l’Apprenant à
              l’initiative de l’École.
            </p>
            <br />
            <span className="bold underline subtitle-color">
              11.2. Engagement de l’Apprenant dans la démarche qualité de
              l’École
            </span>{" "}
            <p className="cgv-section-text">
              L’Apprenant s’engage à répondre aux enquêtes et questionnaires
              remis par l’École dans le cadre de sa formation, afin de permettre
              à cette dernière un suivi pertinent de la formation de l’Apprenant
              et d’assurer ses engagements dans le cadre du processus qualité
              dans lequel elle est engagée. Sans réponse de sa part aux
              questionnaires reçus aux points clés de sa formation par
              notification, le service relation apprenant de l’École prendra
              contact avec ce dernier.
            </p>
            <br />
            <span className="bold underline subtitle-color">
              11.3. Utilisation du code personnel et du mot de passe par
              l’Apprenant
            </span>{" "}
            <p className="cgv-section-text">
              Le code d'accès personnel et le mot de passe, choisi par
              l’Apprenant pour se connecter à la plateforme de formation lors de
              la confirmation de son inscription, sont personnels et
              confidentiels. L’Apprenant s'engage à conserver secrets les codes
              personnels et les mots de passe, et à ne pas les divulguer sous
              quelque forme que ce soit, aussi bien pour l’accès à la plateforme
              de formation digitale de l’Apprenant ou l’accès au logiciel tiers
              mis à disposition de l’Apprenant dans le cadre de sa formation,
              sous peine de poursuite de la part de l'École.
            </p>
            <p className="cgv-section-text">
              La formation est à usage exclusif du titulaire du Contrat. Il ne
              peut en aucun cas être cédé à des tiers à titre gratuit ou payant.
              Il est précisé que tout usage du code personnel et du mot de passe
              est fait sous l'entière responsabilité de l’Apprenant.
            </p>
            <br />
            <span className="bold underline subtitle-color">
              11.4. Utilisation du code personnel et du mot de passe par
              l’Apprenant
            </span>{" "}
            <p className="cgv-section-text">
              Les services de formation à distance diffusés sur le réseau
              Internet sont normalement accessibles 24 heures sur 24, 7 jours
              sur 7, sauf cas de force majeure, évènement hors de contrôle de
              l’École et/ou de l’hébergeur du service, pannes éventuelles ou
              interventions de maintenance liée à l’évolution technologique de
              la plateforme de formation ou rendue nécessaire pour assurer le
              bon fonctionnement des services.
            </p>
            <p className="cgv-section-text">
              L’École s’engage à mettre en œuvre tous les moyens à sa
              disposition pour garantir l’accessibilité et la continuité des
              services de la plateforme. L’École n’étant tenue qu’à une simple
              obligation de moyens concernant la continuité de l’accès au
              service.
            </p>
            <p className="cgv-section-text">
              En cas d’indisponibilité des services de formation à distance,
              l’Apprenant peut contacter l’École à l’adresse suivante :{" "}
              <a
                href="mailto:apprenant@wyze-academy.com"
                aria-label="Cliquer ici si vous êtes apprenant et vous avez des problèmes d'accès aux services de formation à distance"
              >
                <span className="bold underline">
                  apprenant@wyze-academy.com
                </span>{" "}
              </a>
              &nbsp; pour faire part de tout problème.
            </p>
          </div>

          <div className="cgv-section" id="cgv-apprentice-duties">
            <h2 className="cgv-section-title">12. RESPONSABILITÉ </h2>
            <span className="bold underline subtitle-color">
              12.1. Responsabilité de l’Apprenant
            </span>{" "}
            <p className="cgv-section-text">
              L’Apprenant est seul responsable du choix des services de
              formation à distance proposés par l’École, de leur conservation et
              de leur utilisation dans le cadre du respect de la Politique de
              protection des données personnelles, du Règlement intérieur et des
              Conditions Générales d’Utilisation de la Plateforme digitale.
              L’Apprenant déclare connaître et accepter les caractéristiques et
              les limites de la transmission d’informations par le réseau
              internet, ainsi que les coûts propres à la connexion à ce réseau.
              Il reconnaît qu’il lui appartient de s’assurer que les
              caractéristiques techniques du matériel qu’il utilise lui
              permettent un accès aux services de formation à distance dans de
              bonnes conditions, en particulier en ce qui concerne la
              transmission de documents par internet, et de prendre toutes
              mesures appropriées pour être protégé d’une contamination par
              d’éventuels programmes contenant un virus.
            </p>
            <br />
            <span className="bold underline subtitle-color">
              12.2. Responsabilité de l’École
            </span>{" "}
            <p className="cgv-section-text">
              - &nbsp; A l’égard de l’Apprenant, la responsabilité de l’École
              peut être engagée en cas de manquement à l’une de ses obligations.
              <br />
            </p>
            <p className="cgv-section-text">
              - &nbsp; A l’égard du financeur du Service souscrit par
              l’Apprenant (Entreprise de l’Apprenant, Tiers Financeurs), la
              responsabilité de l’École ne peut être engagée qu’en cas de faute
              ou de négligence prouvée et sera limitée aux préjudices directs ,
              à l’exclusion de tout préjudice indirect, de quelque nature que ce
              soit et notamment toute perte de chance , de résultat,
              d’exploitation, ou perte de données et/ou fichiers.
              <br />
            </p>
            <p className="cgv-section-text">
              - &nbsp; Il est expressément convenu que, si la responsabilité de
              l'École était retenue dans l'exécution du Contrat, le financeur de
              la formation ne pourrait prétendre à d'autres indemnités et
              dommages et intérêts que le remboursement des règlements effectués
              au titre des frais de formation.
              <br />
            </p>
            <p className="cgv-section-text">
              L'École dégage toute responsabilité quant au contenu du forum des
              Apprenants.
            </p>
          </div>
          <div className="cgv-section" id="cgv-intellectual-property">
            <h2 className="cgv-section-title">13. PROPRIÉTÉ INTELLECTUELLE </h2>
            <p className="cgv-section-text">
              <span className="bold underline subtitle-color">13.1. </span>
              &nbsp; Le contenu des Services est la propriété de l’École et
              celle de ses partenaires. Il est comme tel strictement réservé au
              titre du droit d'auteur ainsi qu'au titre de la propriété
              intellectuelle par leurs titulaires et pour le monde entier. En
              conséquence, toute reproduction, imitation ou représentation
              totale ou partielle non autorisée des marques, logos, signes
              distinctifs et contenus des Services constitue une violation du
              droit d'auteur passible de poursuites civiles et pénales.
            </p>
            <br />
            <p className="cgv-section-text">
              <span className="bold underline subtitle-color">13.2.</span>&nbsp;
              Les supports de formation, qu'ils soient imprimés ou numériques,
              sont réservés aux seules fins de l'utilisation par l’Apprenant
              même. La diffusion, la revente et la location des supports
              imprimés ou numériques, quelle que soit leur présentation,
              constitue une violation déterminante des CGV et pourra justifier
              de l’exclusion définitive de l’Apprenant et d'un recours en
              justice de la part de l'École.
            </p>
            <br />
            <p className="cgv-section-text">
              <span className="bold underline subtitle-color">13.3.</span>&nbsp;
              Toute mise à disposition à des fins commerciales non autorisées
              des supports de formation (cours, exercices, vidéos, logiciels) de
              l'École, protégés par des droits d'auteur, sur un site internet
              personnel ou commercial, ou sur un quelconque support numérique ou
              imprimé est strictement interdit et aboutira à l’exclusion de
              l’Apprenant de notre établissement. Des poursuites judiciaires
              seront alors engagées par l'École.
            </p>
          </div>

          <div
            className="cgv-section"
            id="cgv-confidentiality-and-personal-data"
          >
            <h2 className="cgv-section-title">
              14. CONFIDENTIALITÉ ET PROTECTION DES DONNÉES À CARACTÈRE
              PERSONNEL{" "}
            </h2>
            <p className="cgv-section-text">
              <span className="bold underline subtitle-color">14.1. </span>
              &nbsp; Wyze Academy s'engage à garder strictement confidentiels et
              à ne pas divulguer ou laisser divulguer ou communiquer à
              quiconque, par quelque moyen que ce soit, les documents, données,
              prototypes, informations, outils, logiciels (ci-après désignés
              globalement « les Informations ») transmis par l’Apprenant ou dont
              il aurait eu connaissance à l'occasion de ses relations avec
              l’Apprenant.
            </p>
            <br />

            <p className="cgv-section-text">
              <span className="bold underline subtitle-color">14.2. </span>
              &nbsp; Pour les besoins de la présente clause on entend par «
              Données Personnelles » les données à caractère personnel telles
              que définies par le règlement (UE) 2016/679 du Parlement Européen
              et du Conseil du 27 avril 2016 (« RGPD »).
            </p>
            <p className="cgv-section-text">
              Wyze Academy reconnaît que les Données Personnelles des Apprenants
              et leurs traitements y afférents sont soumis aux dispositions
              légales et réglementaires de protection des données à caractère
              personnel applicables dont notamment le règlement (UE) 2016/679 du
              Parlement Européen et du Conseil du 27 avril 2016, applicable à
              compter du 25 mai 2018 et toutes réglementations locales prises en
              application ou complément de ce dernier (ci-après ensemble le «
              RGPD ») et chacune d’elles s’engage à respecter le RGPD.
            </p>
            <br />
            <p className="cgv-section-text">
              A ce titre Wyze Academy s’engage à :
            </p>
            <br />
            <p className="cgv-section-text">
              - &nbsp; Traiter les données uniquement pour la ou les seule(s)
              finalité(s) qui fait / font l’objet de l’exécution du Contrat ou
              la Convention de formation professionnelle et l’exécution de ses
              obligations légales et réglementaires
            </p>
            <p className="cgv-section-text">
              - &nbsp; Assurer la sécurité des données personnelles et mettre en
              place les mesures de sécurité appropriées
            </p>
            <p className="cgv-section-text">
              - &nbsp; Gérer la rétention des données conformément au
              référentiel des délais de conservation
            </p>
            <p className="cgv-section-text">
              - &nbsp; Wyze Academy assure à l’Apprenant droit d’accès et de
              rectification, de suppressions, d’opposition et de portabilité
            </p>
            <p className="cgv-section-text">
              Pour toute question relative à la présente section de protection
              des données personnelles ou pour toute demande relative à vos
              données personnelles, vous pouvez nous contacter en adressant un
              e-mail à l’adresse électronique{" "}
              <a
                href="mailto:contact@wyze-academy.com"
                aria-label="Cliquer ici pour nous contacter sur le sujet de protection de vos données personnelles"
              >
                <span className="bold underline">contact@wyze-academy.com</span>{" "}
              </a>
            </p>
            <br />
            <p className="cgv-section-text">
              Si vous souhaitez de plus amples informations sur la protection
              des données personnelles ou introduire une réclamation :
            </p>
            <p className="ms-2">
              Commission nationale de l’informatique et des libertés (CNIL)
            </p>
            <p className="ms-2">
              3 place de Fontenoy - TSA 80715 - 75334 PARIS CEDEX 07
            </p>
            <p className="ms-2">Téléphone : +33 (0)1 53 73 22 22</p>
            <p className="ms-2">
              <a
                href="https://www.cnil.fr/fr/adresser-une-plainte"
                aria-label="Adresser une plainte via le site de la cnil"
              >
                <span className="bold underline">
                  https://www.cnil.fr/fr/adresser-une-plainte
                </span>
              </a>
            </p>
            <Strie scale={1.5} culor="blue" left="-20%" top="0" zindex={-1} />
            <Strie scale={3} culor="blue" left="-15%" top="50%" zindex={-1} />
            <Strie scale={5} culor="red" left="100%" top="0%" zindex={-1} />
            <Strie scale={3} culor="red" left="110%" top="10%" zindex={-1} />
          </div>

          <div
            className="cgv-section"
            id="cgv-remote-education-service-suspension"
          >
            <h2 className="cgv-section-title">
              15. SUSPENSION DE L’ACCÈS AUX SERVICES DE FORMATION À DISTANCE
            </h2>
            <p className="cgv-section-text">
              L’École se réserve, après notification écrite à l’Apprenant, le
              droit de suspendre l’accès aux Services en cas d’infraction
              constatée à l’article 7 sur les conditions de règlement des frais
              de formation, à l’article 11 sur les obligations des parties et à
              l’article 13 sur la propriété intellectuelle.
            </p>
            <p className="cgv-section-text">
              Cette suspension de l’accès aux services de formation à distance
              ne dispense pas l’Apprenant de son obligation de règlement des
              frais de formation et l’École ne pourra faire l’objet d’aucune
              demande de remboursement.
            </p>
            <p className="cgv-section-text">
              Dans l’hypothèse où ces manquements perdureraient pendant un délai
              d’un mois après envoi d’une lettre recommandée avec accusé de
              réception, Wyze Academy peut résilier le contrat.
            </p>
          </div>

          <div className="cgv-section" id="cgv-legal-conformity-guaranty">
            <h2 className="cgv-section-title">
              16. GARANTIE LÉGALE DE CONFORMITÉ
            </h2>
            <p className="cgv-section-text">
              Le consommateur a droit à la mise en œuvre de la garantie légale
              de conformité en cas d'apparition d'un défaut de conformité durant
              l’exécution du Contrat à compter un délai de 2 ans à compter de la
              fourniture du contenu numérique ou du service numérique. Durant ce
              délai, le consommateur n'est tenu d'établir que l'existence du
              défaut de conformité et non la date d'apparition de celui-ci.
            </p>
            <br />
            <p className="cgv-section-text">
              La garantie légale de conformité emporte obligation de fournir
              toutes les mises à jour nécessaires au maintien de la conformité
              du contenu numérique ou du service numérique durant l’exécution du
              Contrat.
            </p>
            <br />
            <p className="cgv-section-text">
              La garantie légale de conformité donne au consommateur droit à la
              mise en conformité du contenu numérique ou du service numérique
              sans retard injustifié suivant sa demande, sans frais et sans
              inconvénient majeur pour lui.
            </p>
            <br />
            <p className="cgv-section-text">
              Le consommateur peut obtenir une réduction du prix en conservant
              le contenu numérique ou le service numérique, ou il peut mettre
              fin au contrat en se faisant rembourser intégralement contre
              renoncement au contenu numérique ou au service numérique, si :
            </p>

            <ul className="cgv-section-list">
              <li className="cgv-section-list-item">
                <p className="cgv-section-text">
                  Le professionnel refuse de mettre le contenu numérique ou le
                  service numérique en conformité
                </p>
              </li>
              <li className="cgv-section-list-item">
                <p className="cgv-section-text">
                  La mise en conformité du contenu numérique ou du service
                  numérique est retardée de manière injustifiée
                </p>
              </li>
              <li className="cgv-section-list-item">
                <p className="cgv-section-text">
                  La mise en conformité du contenu numérique ou du service
                  numérique ne peut intervenir sans frais imposés au
                  consommateur
                </p>
              </li>
              <li className="cgv-section-list-item">
                <p className="cgv-section-text">
                  La mise en conformité du contenu numérique ou du service
                  numérique occasionne un inconvénient majeur pour le
                  consommateur
                </p>
              </li>
              <li className="cgv-section-list-item">
                <p className="cgv-section-text">
                  La non-conformité du contenu numérique ou du service numérique
                  persiste en dépit de la tentative de mise en conformité du
                  professionnel restée infructueuse
                </p>
              </li>
            </ul>
            <p className="cgv-section-text">
              Le consommateur a également droit à une réduction du prix ou à la
              résolution du contrat lorsque le défaut de conformité est si grave
              qu'il justifie que la réduction du prix ou la résolution du
              contrat soit immédiate. Le consommateur n'est alors pas tenu de
              demander la mise en conformité du contenu numérique ou du service
              numérique au préalable.
            </p>
            <br />
            <p className="cgv-section-text">
              Dans les cas où le défaut de conformité est mineur, le
              consommateur n'a droit à l'annulation du contrat que si le contrat
              ne prévoit pas le paiement d'un prix.
            </p>
            <br />
            <p className="cgv-section-text">
              Toute période d'indisponibilité du contenu numérique ou du service
              numérique en vue de sa remise en conformité suspend la garantie
              qui restait à courir jusqu'à la fourniture du contenu numérique ou
              du service numérique de nouveau conforme.
            </p>
            <br />
            <p className="cgv-section-text">
              Ces droits résultent de l'application des articles L. 224-25-1 à
              L. 224-25-31 du code de la consommation.
            </p>
            <br />
            <p className="cgv-section-text">
              Le professionnel qui fait obstacle de mauvaise foi à la mise en
              œuvre de la garantie légale de conformité encourt une amende
              civile d'un montant maximal de 300.000 euros, qui peut être porté
              jusqu'à 10 % du chiffre d'affaires moyen annuel (article L.
              242-18-1 du code de la consommation).
            </p>
            <br />
            <p className="cgv-section-text">
              Le consommateur bénéficie également de la garantie légale des
              vices cachés en application des articles 1641 à 1649 du code
              civil, pendant une durée de deux ans à compter de la découverte du
              défaut. Cette garantie donne droit à une réduction de prix si le
              contenu numérique ou le service numérique est conservé, ou à un
              remboursement intégral contre renonciation au contenu numérique ou
              au service numérique.
            </p>
            <Strie scale={1.5} culor="blue" left="-20%" top="20%" zindex={-1} />
            <Strie scale={3} culor="blue" left="-15%" top="60%" zindex={-1} />
            <Strie scale={5} culor="red" left="100%" top="20%" zindex={-1} />
            <Strie scale={3} culor="red" left="110%" top="30%" zindex={-1} />
          </div>

          <div
            className="cgv-section"
            id="cgv-applicable-rights-and-dispute-settlement"
          >
            <h2 className="cgv-section-title">
              17. DROIT APPLICABLE ET RÈGLEMENT DES LITIGES
            </h2>
            <p className="cgv-section-text">
              Le Contrat sera exécuté et interprété conformément au droit
              français.
              <br />
              Avant tout contentieux, les parties chercheront à s’entendre au
              travers d’un éventuel accord amiable.
              <br />
              Lorsque l’Apprenant a adressé une réclamation écrite à l'École, et
              que les suites données à celle-ci ne lui ont pas donné
              satisfaction, il peut, conformément à l'article L. 612-1 du Code
              de la consommation, introduire gratuitement une demande de
              résolution amiable par voie de médiation. Le médiateur doit être
              saisi dans le délai maximal d’un an à compter de la réclamation
              initiale auprès de Wyze Academy.
            </p>
            <br />
            <p className="cgv-section-text">
              Le médiateur compétent peut être saisi directement en ligne aux
              coordonnées suivantes :{" "}
              <a
                href="https://cm2c.net/"
                aria-label="Saisir un médiateur en ligne sur le site de CM2C"
              >
                <span className="bold underline">https://cm2c.net/</span>
              </a>{" "}
              ou CM2C, à l’adresse 49 rue de Ponthieu 75008 PARIS.
            </p>
            <p className="cgv-section-text">
              L’Apprenant a également la possibilité de s’adresser à la
              plateforme européenne de règlement des litiges en ligne disponible
              via le lien suivant :
              <br />
              <a
                href="https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.show&lng=FR."
                aria-label="S'adresser à la plateforme européenne de règlement des litiges en ligne"
              >
                <span className="bold underline">
                  https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.show&lng=FR.
                </span>
              </a>
            </p>
            <p className="cgv-section-text">
              Cette plateforme a pour but de proposer au consommateur des
              procédures de règlement amiable de litiges offertes par des
              entités qualifiées pour traiter des litiges entre consommateurs et
              entreprises ou professions libérales situés dans l’Union
              européenne.
            </p>
            <br />
            <p className="cgv-section-text">
              A défaut de règlement amiable, le litige sera porté devant les
              juridictions compétentes et, si la loi le permet, devant les
              tribunaux du ressort de Bobigny.
            </p>
            <br />
            <p className="cgv-section-text">
              Toute demande d’information ou réclamation relative aux CGV doit
              être adressée par email à{" "}
              <a
                href="mailto:direction@wyze-academy.com"
                aria-label="Nous contacter pour toute demande d'informations relatives aux CGV."
              >
                <span className="bold underline">
                  direction@wyze-academy.com.
                </span>
              </a>
            </p>
            <br />
            <p className="cgv-section-text">
              Dans le cas où une clause contractuelle serait touchée de nullité,
              celle-ci n’entraîne pas la nullité des CGV.
            </p>
            <br />
            <p className="cgv-section-text">
              D’autre part, l’inapplication temporaire ou permanente d’une ou
              plusieurs clauses des CGV par l’École ne saurait valoir
              renonciation de sa part aux autres clauses des CGV qui continuent
              à produire leurs effets.
            </p>
            <br />
            <p className="cgv-section-text">
              Pour les cours dont la durée totale est supérieure à trois mois,
              les 30 % sont calculés sur le prix de la première année
              pédagogique telle qu’elle est prévue par le plan d’études.
            </p>
            <br />
            <p className="cgv-section-text">
              Le contrat doit, à peine de nullité, reproduire les dispositions
              du présent article. Il ne peut comporter de clause attributive de
              compétences.
            </p>
          </div>

          <div className="cgv-section" id="cgv-appendix-1/1">
            <h2 className="cgv-section-title">ANNEXE 1/1</h2>
            <p className="cgv-section-text bold subtitle-color">
              Article L444-8 du Code de l’Éducation
            </p>
            <p className="cgv-section-text bold subtitle-color">
              Version en vigueur depuis le 22 juin 2000
            </p>
            <br />
            <p className="cgv-section-text">
              A peine de nullité, le contrat ne peut être signé qu'au terme d'un
              délai de sept jours après sa réception.
            </p>
            <br />
            <p className="cgv-section-text">
              Le contrat peut être résilié par l'élève, ou son représentant
              légal, si, par suite d'un cas fortuit ou d'une force majeure, il
              est empêché de suivre l'enseignement correspondant. Dans ce cas,
              la résiliation ne donne lieu à aucune indemnité.
            </p>
            <br />
            <p className="cgv-section-text">
              Jusqu'à l'expiration d'un délai de trois mois à compter de la date
              d'entrée en vigueur du contrat, celui-ci peut être unilatéralement
              résilié par l'élève moyennant une indemnité dont le montant ne
              saurait excéder 30 % du prix du contrat, fournitures non
              comprises. Les sommes déjà versées peuvent être retenues à due
              concurrence.
            </p>
            <br />
            <p className="cgv-section-text">
              Les livres, objets ou matériels dont le contrat prévoyait la
              fourniture à l'élève et qui ont été effectivement livrés à la date
              de la résiliation, restent acquis pour la valeur estimée au
              contrat.
            </p>
            <br />
            <p className="cgv-section-text">
              Il ne peut être payé par anticipation plus de 30 % du prix
              convenu, fournitures non comprises.
            </p>
            <br />
            <p className="cgv-section-text">
              Le contrat doit, à peine de nullité, reproduire les dispositions
              du présent article. Il ne peut comporter de clause attributive de
              compétence.
            </p>
            <br />
            <Strie scale={5} culor="red" left="-20%" top="20%" zindex={-1} />
            <Strie scale={2} culor="red" left="-19%" top="140%" zindex={-1} />
            <Strie scale={6} culor="blue" left="115%" top="40%" zindex={-1} />
          </div>

          <div className="cgv-footer">
            <span>
              <p>07 45 62 88 26</p>
              <p>
                <a
                  href="mailto:secretariat@wyze-academy.com"
                  aria-label="Contacter le secretariat via email"
                >
                  <span className="bold unerline">
                    secretariat@wyze-academy.com
                  </span>
                </a>
              </p>
              <p>Siège social : 21 avenue Raspail 93420 VILLEPINTE </p>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
