import { useRef, useEffect, useState } from "react";
import "./KidsSection.css";
import IconNext from "../../../../media/icons/IconNext";
import Strie from "../../../../components/Strie/Strie";
import KidImageMain from "../../../../media/images/kid-image-1.jpg";
import KidImageSecond from "../../../../media/images/kid-image-2.jpg";
import KidImageThird from "../../../../media/images/kid-image-3.jpg";
// import { Link } from "react-router-dom";

function KidsSection() {
  /*animation d'apparition sur intersection*/
  const sectionRef = useRef();
  const [isSectionIntersected, setIsSectionIntersected] = useState();
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setIsSectionIntersected(entry.isIntersecting);
    });
    observer.observe(sectionRef.current);
    return () => {
      observer.disconnect();
    };
  });

  return (
    <div className="home-sections-wrapper">
      <div
        ref={sectionRef}
        className={`kids-section-container ${
          isSectionIntersected ? "visible" : ""
        }`}
      >
        <div className="kids-section-illustration">
          <img
            className="kids-section-image main-kids-image"
            src={KidImageMain}
            alt=" kids "
          ></img>
          <img
            className="kids-section-image"
            src={KidImageSecond}
            alt="kids "
          ></img>
          <img
            className="kids-section-image"
            src={KidImageThird}
            alt="kids "
          ></img>
        </div>
        <div className="kids-section-description">
          <div className="kids-section-header">
            <h6>Wyze Kids</h6>
            <h2 className="semi-bold">L’excellence en devenir</h2>
          </div>
          <p>
            Pour vos <span className="bold">enfants</span> et{" "}
            <span className="bols">adolescents</span>, nous enseignons en
            utilisant les mêmes plateformes que les{" "}
            <span className="bold">professionnels</span>. Cela signifie que
            votre enfant apprendra aujourd’hui et deviendra un{" "}
            <span className="bold">expert</span> demain.
          </p>
          <div className="kids-section-footer">
            <a
              href="/construction-progress?title=Kids"
              className="kids-section-button"
            >
              <span>NOS STAGES KIDS</span>
              <IconNext rotation="rotate-right" width={12} height={12} />
            </a>
            <a
              href="/construction-progress?title=Ados"
              className="kids-section-button"
            >
              <span>NOS FORMATIONS ADOS</span>
              <IconNext rotation="rotate-right" width={12} height={12} />
            </a>
          </div>
        </div>
      </div>
      <Strie scale={1.5} color="blue" left="-290px" top="10px" />
      <Strie scale={3} color="blue" left="-260px" top="70px" />
      <Strie scale={12} color="red" left="100%" top="0%" zindex={-1} />
      <Strie scale={3} color="red" left="110%" top="20%" zindex={-1} />
    </div>
  );
}

export default KidsSection;
